import styled from 'styled-components';

interface CardProps {
    color?: string;
    variant?: string;
    sm?: boolean;
    triangle?: boolean;
    border?: boolean;
    bColor?: string;
    md?: boolean;
}

export const Card = styled.div<CardProps>`
    padding: 22px;
    border-radius: 8px;
    position:relative;
    background-color:${props => props.color ? props.color : '#fff'};
    width: 100%;
    height: 100%;
    border: ${props => props.border ? `2px solid ${props.bColor ? props.bColor : '#eef0f2'}` : '0 none'};

    ${props => props.md && `
        display:flex;
        align-items:center;
        justify-content:center;
        gap:10px;
        flex-direction:column;
    `}

    ${props => props.triangle && `
        &:before{
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-left: 45px solid transparent;
            border-top: 37px solid ${props.color};
            position:absolute;
            top:0;
            left:-37px;

            @media (max-width:990px){
                border-left: 28px solid transparent;
                border-top: 20px solid ${props.color};
                position:absolute;
                top:0;
                left:-20px;
            }
        }
    `}
`

export const WCard = styled.div<CardProps>`
    padding: 18px;
    margin: 9px 0;
    margin-top: ${props => props.variant === 'down' && '0'};
    margin-bottom: ${props => props.variant === 'up' && '0'};
    background-color:${props => props.color ? props.color : '#fff'};
    border-radius: ${props => props.variant ? props.variant === 'down' ? '0 0 8px 8px' : '8px 8px 0 0' : '8px'};
    border: 0 none;
    width: 100%;
    height: 100%;
    position:relative;
    
    ${props => props.variant === 'up' && props.triangle && `
        &:before{
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-left: 30px solid transparent;
            border-right: 30px solid transparent;
            border-bottom: 40px solid ${props.color};
            position: absolute;
            top: -30px;
        }
    `}

    ${props => props.variant === 'down' && props.triangle && `
        &:before{
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-top: 30px solid #17a948;
            position:absolute;
            bottom: -30px;
            right: 80px;
        }
    `}

    ${props => props.sm && `
        padding: 10px;
    `}
`

interface ButtonProps {
    bkcolor?: string;
    color?: string;
    bdcolor?: string;
    sm?: boolean;
    hovercolor?: string;
    hoverbk?: string;
    robotId?: number;
}

export const MyButton = styled.button<ButtonProps>`
    display: flex;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    width: 100%;
    color: ${props => props.color ? props.color : '#fff'};
    background:${props => props.bkcolor ? props.bkcolor : 'black'};
    border:${props => props.bdcolor ? `1.5px solid ${props.bdcolor}` : '0 none'};
    font-weight: 600;
    font-size: 16px;
    border-radius: 6px;
    cursor: pointer;
    transition: opacity .3s;
    padding: ${props => props.sm ? `3px 0` : '12px 0'};

    :hover{
        ${props => props.hovercolor || props.hoverbk ? `
            ${props.hovercolor && `color:${props.hovercolor};`}
            ${props.hoverbk && `background:${props.hoverbk};`}
        ` : `
            opacity: .85;
        `}
    }
`

export const RobotBtn = styled(MyButton)`
    ${props => props.robotId === 0 && `
        background:#00bdd6;
        border:1px solid #00bdd6;

        :hover{
            color: #00bdd6;
            background:#ebfdff;
            border:1px solid #00bdd6;
        }`
    }
    ${props => props.robotId === 1 && `
        background:#17a948;
        border:1px solid #17a948;

        :hover{
            color: #17a948;
            border:1px solid #17a948;
            background:#eefdf3;
        }`
    }
    ${props => props.robotId === 2 && `
        background:#ed7d2d;
        border:1px solid #ed7d2d;

        :hover{
            color: #ed7d2d;
            border:1px solid #ed7d2d;
            background:#fef6f1;
        }`
    }
`

export const TitleLine = styled.div`
    font-size:32px;
    font-weight:600;
    text-align:center;
    
    @media (max-width:990px){
        font-size:24px;
    }

`

export const AllPlanBtn = styled(RobotBtn)`
    width:150px;
    font-size:16px;
    padding:10px;
    @media (max-width:990px){
        font-size:12px;
        padding:5px;
        width:100px;
    }
`