interface TimeStorageProps {
    expireAt: number,
    obj: any,
    verifyItem: string
}

const RegularLocalStorage = (storeName: string, verifyItem: string, storeFun: Function) => {
    let storeObj = localStorage.getItem(storeName)
    if (storeObj !== null) {
        let _storeObj: TimeStorageProps = JSON.parse(storeObj)
        if (_storeObj.expireAt < Date.now() || _storeObj.verifyItem !== verifyItem) {
            localStorage.removeItem(storeName)
            storeFun()
            return null
        } else {
            return _storeObj.obj
        }
    } else {
        storeFun()
        return null
    }

}

export default RegularLocalStorage