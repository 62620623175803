import { Col, Row } from 'react-bootstrap';
import Footer from '../components/footer';
import Header from '../components/header';
import { RobotInform } from '../components/robot-inform';
import { FooterWapper } from '../components/styles/Footer.styled';
import { RobotBox, RobotCard, RobotImgBox } from '../components/styles/RobotMenu.styled';

export const ChooseRobot = () => {
    return (
        <FooterWapper>
            <Header />

            <RobotBox>
                <Row xl={3} md={2} sm={1} xs={1}>
                    <Col >
                        <RobotCard>
                            <RobotImgBox >
                                <img src="/images/robot0.png" alt="" className='robot robot0' />
                                <img src="/images/rb_bk0.png" alt="" className='rb_bk rb_bk0' />
                            </RobotImgBox>
                            <RobotInform idx={0} />
                        </RobotCard>
                    </Col >
                    <Col >
                        <RobotCard>
                            <RobotInform idx={1} />
                            <RobotImgBox >
                                <img src="/images/robot1.png" alt="" className='robot robot1' />
                                <img src="/images/rb_bk1.png" alt="" className='rb_bk rb_bk1' />
                            </RobotImgBox>
                        </RobotCard>
                    </Col >
                    <Col >
                        <RobotCard>
                            <RobotImgBox >
                                <img src="/images/robot2.png" alt="" className='robot robot2' />
                                <img src="/images/rb_bk2.png" alt="" className='rb_bk rb_bk2' />
                            </RobotImgBox>
                            <RobotInform idx={2} />
                        </RobotCard>
                    </Col>
                </Row>
            </RobotBox>

            <Footer />
        </FooterWapper>
    );
};