import qs from 'qs';
import { useTranslation } from "react-i18next";
import NumberFormat from 'react-number-format';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    ResponsiveContainer, Text,
    Tooltip,
    XAxis, YAxis
} from 'recharts';
import { Button } from '../../components/styles/Button.styled';
import { TduContactLaterSection } from '../styles/GetUsage.styled';
import { useCookies } from 'react-cookie';

export const ShowUsage = ({
    source,
    data,
    unusualMonth,
    isPredictedData,
    showSmtView,
    showMyaccountView
}: {
    source: string
    data: any,
    unusualMonth: string[],
    isPredictedData: boolean,
    showSmtView: Function,
    showMyaccountView: Function
}) => {
    const { t } = useTranslation('usage');
    const navigate = useNavigate();
    const location = useLocation();
    const [cookies, setCookie, removeCookie] = useCookies(['actual_usage']);
    const robotId = parseInt(location.pathname.split('/').pop() || '0');


    const months = [t('Jan', { ns: 'common' }), t('Feb', { ns: 'common' }), t('Mar', { ns: 'common' }), t('Apr', { ns: 'common' }), t('May', { ns: 'common' }), t('Jun', { ns: 'common' }),
    t('Jul', { ns: 'common' }), t('Aug', { ns: 'common' }), t('Sep', { ns: 'common' }), t('Oct', { ns: 'common' }), t('Nov', { ns: 'common' }), t('Dec', { ns: 'common' })];

    let chartData = [];
    let totalUsage = 0;
    let usage: string[] = [];

    for (let i in data) {
        usage.push(data[i]);
    }

    for (let i in usage) {
        totalUsage += parseInt(usage[i]);
        chartData.push({ name: months[parseInt(i)], value: parseInt(usage[i]) })
    }

    const handleContinue = () => {
        const { address, esiid, zipcode, city, state, utcode } = qs.parse(location.search, { ignoreQueryPrefix: true });
        let params = `?address=${encodeURIComponent(`${address}`)}&esiid=${esiid}&zipcode=${zipcode}&city=${(encodeURIComponent(`${city}`))}&state=${state}&usagefrom=${source}&utcode=${utcode}`;
        if (!isPredictedData && usage) {
            params += `&actualusage=${usage.join('-')}`;
        }
        setCookie('actual_usage', usage.join('-'), { path: '/', maxAge: 3600 });
        if (location.pathname.indexOf('get_usage') !== -1) {
            navigate(`/suggest_plans/${robotId}${params}`);
        }else{
            navigate(`${location.pathname}${params}`);
        }
    }

    return (
        <div>
            <div className="text-center">
                <h2 className="mt-3">{
                    isPredictedData ? t('Thank you for your patience') :
                        t('Your usage insight by month')
                }</h2>
                {
                    isPredictedData &&
                    <p>{t('But it seems like your address does not support instant data sharing with your utility company at this time.')} {t('Below is the forecast usage data based on zip code and your house size')}</p>
                }
            </div>
            <div className="d-flex align-items-center justify-content-center mt-5">
                <div style={{ flex: 1 }} className="text-center"><div style={{ fontSize: 14 }}>{t('Annual Usage')}:</div><b className="ms-1"><NumberFormat value={totalUsage} displayType={'text'} thousandSeparator={true} suffix=" kWh" /></b></div>
                <div style={{ flex: 1 }} className="text-center"><div style={{ fontSize: 14 }}>{t('Avg. Monthly Usage')}:</div><b className="ms-1"><NumberFormat value={Math.round(totalUsage / 12 * 100) / 100} displayType={'text'} thousandSeparator={true} suffix=" kWh" /></b></div>
            </div>
            <div className="mt-3">
                <ResponsiveContainer width="100%" height={260}>
                    <BarChart
                        data={chartData}
                        margin={{
                            top: 12, right: 24, left: -12, bottom: 0,
                        }}
                    >
                        <XAxis
                            dataKey="name"
                            tick={(e) => {
                                const { payload: { value } } = e;
                                let unusual = false;

                                const monthNumber = `${months.indexOf(value) + 1}`;
                                if (unusualMonth.indexOf(monthNumber) >= 0) {
                                    e["fill"] = '#f07d74';
                                    unusual = true;
                                }
                                else {
                                    e["fill"] = '#999';
                                    unusual = false;
                                }
                                return <Text {...e} style={{ fontSize: 11 }}>{`${value}${unusual ? ' *' : ''}`}</Text>
                            }}
                            stroke="#999"
                            interval={0}
                        />
                        <YAxis tick={{ fontSize: 11, fill: '#999' }} stroke="#fff" />
                        <CartesianGrid vertical={false} strokeDasharray="2" />
                        <Tooltip
                            itemStyle={{
                                fontSize: 13,
                                fontWeight: 'bold',
                                color: '#fff'
                            }}
                            labelStyle={{
                                fontSize: 11,
                                fontWeight: 'normal',
                                color: '#fff'
                            }}
                            contentStyle={{
                                backgroundColor: 'rgba(0, 0, 0, .6)',
                                border: 0,
                                borderRadius: 4
                            }}
                            cursor={{
                                fill: '#f7f7f7'
                            }}
                            content={({ active, payload, label }) => {
                                if (active && label && payload) {
                                    return (
                                        <div className="chart-tooltip">
                                            <div className="date">{label}</div>
                                            <div className="value">{payload[0].value + 'kwh'}</div>
                                        </div>
                                    );
                                }
                                return null;
                            }}
                        />
                        <Bar dataKey="value"
                            isAnimationActive={true}
                            radius={10}
                            maxBarSize={10}
                        >
                            {chartData.map((entry, idx) => (
                                <Cell key={idx} fill="#fbbb48" />
                            ))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </div>
            {
                unusualMonth.length > 0 &&
                <p style={{ fontSize: 13, color: '#999', lineHeight: 1.1, marginTop: 12 }}>* {t('Changed REP during this month and the calculation of electricity usage differs from other months')}</p>
            }
            <div className="text-center mt-5">
                <Button onClick={handleContinue}>{
                    isPredictedData ? t('Continue with Forecast Usage') :
                        t('Continue')
                }</Button>
            </div>
            {
                isPredictedData &&
                <TduContactLaterSection>
                    <p>{t("Or Click below to get your usage data by logging on to your existing energy provider's account or manually entering Smart Meter Texas (SMT) number")}</p>
                    <div id="get-usage-option-wrapper">
                        <div className="get-usage-option text-start" onClick={() => showSmtView()}>
                            <div>
                                <h5>{t('Use Smart Meter Texas (SMT) Data')}</h5>
                                <p>{t('Instant access of your usage history through your smart meter number')}</p>
                            </div>
                        </div>
                        <div className="get-usage-option text-start" onClick={() => showMyaccountView()}>
                            <div>
                                <h5>{t('MyAccount')}</h5>
                                <p>{t('Instant access of your usage history through MyAccount')}</p>
                            </div>
                        </div>
                    </div>
                </TduContactLaterSection>
            }
        </div>
    )
}