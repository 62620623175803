import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { CheckboxItemProps } from '../types'
import { useLocation } from 'react-router-dom';

const checkColor = ["#00bdd6", '#117b34', '#ed7d2d']

export const CheckboxItem: React.FC<CheckboxItemProps> = ({
    label,
    value,
    checked,
    handleClick,
    onlyread,
    disabled,
    isRadio,
    full,
    error,
    id
}) => {
    const location = useLocation()
    const idx = parseInt(location.pathname.split('/').pop() || '0');


    if (value === '0') return null;
    return (
        <div
            className={`checkbox-item ${onlyread ? 'onlyread' : ''} ${full ? 'full' : ''}`}
            onClick={() => { if (!disabled && !onlyread) handleClick(value); }}
            data-test-id={id ? id : ''}
        >
            {
                checked ?
                    <FontAwesomeIcon icon={isRadio ? "check-circle" : "check-square"} style={{ color: checkColor[idx], fontSize: 20 }} /> :
                    <FontAwesomeIcon icon={isRadio ? "circle" : "square"} style={{ color: '#ddd', fontSize: 20 }} />
            }
            <span className={error ? 'error' : ''}>{label}</span>
        </div>
    )
}