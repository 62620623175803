import { css } from '@emotion/css';
import { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchAddress, fetchUtility } from './api/api';
import { Button } from './components/styles/Button.styled';
import type { Address, Utilities } from './types';
import { ChooseDataSourceModal } from './components/choose-data-source-modal';

export const ValidateAddress = (props: { robotIdx?: number }) => {
    let { robotIdx } = props
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [addressOptions, setAddressOptions] = useState<Address[]>([]);
    const [selectedAddress, setSelectedAddress] = useState<Address[]>([]);
    // const [cookies, setCookie, removeCookie] = useCookies(['consent_id']);
    const [showDataSourceModal, setShowDataSourceModal] = useState(false);
    const location = useLocation();
    const [loadingUtility, setLoadingUtility] = useState(false);
    const [utility, setUtility] = useState<Utilities[]>([]);
    const [selectedUtility, setSelectedUtility] = useState<Utilities | undefined>(undefined);

    useEffect(() => {
    }, []);

    useEffect(() => {
        if (selectedAddress.length === 0) return;
        setLoadingUtility(true);
        const { zipcode } = selectedAddress[0];
        fetchUtility(zipcode).then(res => {
            setLoadingUtility(false);
            if (res) {
                setUtility(res);
                setSelectedUtility(res[0]);
            }
        })
    }, [selectedAddress])

    const handleSearch = (val: string) => {
        setLoading(true);
        fetchAddress(val).then(data => {
            setAddressOptions(data);
            setLoading(false);
        })
    }

    const handleClick = () => {
        if (selectedAddress.length === 0) return;
        const address = selectedAddress[0];
        localStorage.setItem('saved_address', address.street);
        if (robotIdx !== undefined) {
            setShowDataSourceModal(true)
        } else {
            navigate(`${location.pathname}?address=${encodeURIComponent(`${address.street}`)}&esiid=${address.esiid}&zipcode=${address.zipcode}&city=${address.city}&state=${address.state}&utcode=${utility[0].utility_code}`);
            window.location.reload()
        }
    }

    const redirectTo = (type: string) => {
        const address = selectedAddress[0];
        const { street, esiid, zipcode, city, state } = address;
        const utilityCode = selectedUtility ? selectedUtility.utility_code : '';
        if (type === 'plan') {
            navigate(`/suggest_plans/${robotIdx}?address=${encodeURIComponent(`${address.street}`)}&esiid=${address.esiid}&zipcode=${address.zipcode}&city=${address.city}&state=${address.state}&utcode=${utility[0].utility_code}`);
        }
        else {
            navigate(`/get_usage/${robotIdx}?address=${encodeURIComponent(street)}&esiid=${esiid}&zipcode=${zipcode}&city=${city}&state=${state}&source=${type}&utcode=${utilityCode}`);
        }
    }

    const chooseUtility = () => {
        if (utility.length <= 1 || !selectedUtility) return null;
        return (
            <Dropdown className="utility-select">
                <Dropdown.Toggle variant="light">
                    {selectedUtility.utility_name}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {
                        utility.map((val, idx) => (
                            <Dropdown.Item
                                eventKey={idx}
                                key={idx}
                                onClick={() => {
                                    setSelectedUtility(val);
                                }}
                                active={val.utility_id === selectedUtility.utility_id}
                            >{val.utility_name}</Dropdown.Item>
                        ))
                    }
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    return (
        <div className='mt-5'>
            <div style={{ flex: 1 }}>
                <p className={css`
                font-size:14px;
                padding-left:20px;
                font-weight: 600;
                margin-bottom: 0;
            `}>Address</p>
                <div className="autocomplete-address-row">
                    <div id="autocomplete-wrapper">
                        <AsyncTypeahead
                            filterBy={() => true}
                            id="autocomplete"
                            isLoading={loading}
                            labelKey="label"
                            minLength={1}
                            onSearch={handleSearch}
                            options={addressOptions}
                            placeholder={`Please Enter Your Address`}
                            size="lg"
                            onChange={
                                (val: any) => {
                                    setSelectedAddress(val)
                                }
                            }
                            selected={selectedAddress}
                        />
                    </div>
                    {chooseUtility()}
                </div>
            </div>
            <Button
                onClick={handleClick}
                disabled={!selectedAddress[0] || loadingUtility}
                md
                className={css`
                    margin-top: 20px;
                    width: 140px;
                `}
            >
                <span>Start Now</span>
            </Button>
            <ChooseDataSourceModal
                show={showDataSourceModal}
                handleClose={() => { setShowDataSourceModal(false); }}
                handleRedirect={redirectTo}
                utility={selectedUtility}
            />
        </div>
    )
}