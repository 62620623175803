import styled from 'styled-components';

export const FooterWapper = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`

export const StyledFooter = styled.div`
    background:#fafafb;
    color: #000;
    padding-top: 44px;
    padding-bottom: 24px;
    margin-top:auto;

    .logo{
        width: 150px;
        height: 25px;
    }
    
    .link-logo{
        height:25px;
        cursor: pointer;
    }

    .logo-eiq{
        display:none;
    }

    @media (max-width: 768px){
        padding-top: 0;

        .logo{
            display:none;
        }
        .logo-eiq{
            display:block;
        }
    }
`

export const FooterNavRow = styled.div`
    display: flex;
    justify-content:space-between;
    gap:40px;

    @media (max-width: 768px){
        flex-direction: column;
    }
`

export const FooterNavCol = styled.div`
    flex: 1;
    justify-content: center;
    text-align:center;

    & > div{
        padding: 4px 0;
    }

    h6{
        font-size: 16px;
        margin-bottom: 16px;
        color:#171A1F;
    }

    a{
        color: #000;
        text-decoration: none;
        font-size: 12px;
        opacity: .6;
    }
`

export const FooterSubscribe = styled.div`
    flex:0 0 300px;

    h5{
        font-size: 16px;
        color: #00BDD6;
        font-weight: 500;
    }

    p{
        font-size: 12px;
        color: #565e6c;
    }
    @media (max-width: 768px){
        flex: 1;
        margin-left:20px;
    }
`

export const Copyright = styled.div`
    font-size: 13px;
    opacity: .5;
    text-align: center;
    font-weight: 300;
    margin-top: 24px;
    border-top:1px solid #DEE1E6;
    padding-top: 20px;

    display: flex;
    justify-content: center;
    a{
        text-decoration: none;
        color: #000;
    }
`