import { css } from '@emotion/css'
import { useRef, useState } from 'react'
import { Col, Container, Modal, Row } from 'react-bootstrap'
import styled from 'styled-components'
import Footer from './components/footer'
import Header from './components/header'
import HomeHeader from './components/home-header'
import { HomeAsideBox, SearchAddressInner } from './components/styles/Homepage.styled'
import { Card, MyButton, RobotBtn, TitleLine } from './components/styles/MyComponents.styled'
import { ValidateAddress } from './validate_address'

const CardTitleLine = styled(TitleLine)`
    font-size: 24px;
    font-weight: 600;
    text-align:start;
    @media(max-width:990px){
        font-size:18px;
    }
`
const CardIntro = styled(TitleLine)`
    font-size: 16px;
    text-align:start;
    @media(max-width:990px){
        font-size:14px;
    }
`

const robots = [{
    name: 'Penny',
    intro: `Penny is a friendly and approachable bot with a knack for saving money. 
    She's always looking out for the best deals and is happy to help customers find the most affordable electricity and gas plans. `,
    text: `Penny has a warm and inviting personality that puts customers at ease.`,
    cardBk: '#f5feff',
    btnColor: "#00bdd6",
}, {
    name: 'Aurora',
    intro: `Aurora is a forward-thinking and eco-conscious bot. She's passionate about helping customers reduce their carbon footprint and find electricity and gas plans that are sustainable and affordable.`,
    text: `Aurora is knowledgeable about green energy options and is always eager to share her expertise.`,
    cardBk: '#f6fef9',
    btnColor: "#17a948",
}, {
    name: 'Tempo',
    intro: `Tempo is a dynamic and efficient bot. She's adept at analyzing customer energy usage patterns and finding electricity and gas plans that fit their lifestyle. `,
    text: `Tempo is focused on helping customers save time and money by maximizing their energy savings.`,
    cardBk: '#fefaf8',
    btnColor: "#ed7d2d",
}]

const CardContent = [{
    title: `Comprehensive Market Data`,
    intro: `Gain a comprehensive view of energy plans with our vast database, ensuring you have access to all available options in the markeT`,
    bk: "#f6fef9"
},
{
    title: `Fastest and Smartest AI Engine`,
    intro: `Our lightning-fast AI engine quickly analyzes your usage, matching it with the best electricity plan recommendations in seconds`,
    bk: "#f5feff"
},
{
    title: `Trusted Energy Provider`,
    intro: `We partner with the industry's most trusted providers, ensuring reliable service and empowering you with trustworthy and competitive energy plan options`,
    bk: "#fefaf8"
},
{
    title: `Easy Plan Management`,
    intro: `Streamline your energy plan management effortlessly through our user-friendly system,enabling easy navigation,comparison, and seamless plan updates.`,
    bk: "#f8f9fe"
}]

export const Homepage = () => {

    const [show, setShow] = useState(false);
    const [robotIdx, setRobotIdx] = useState(0)
    const scrollRef = useRef<HTMLDivElement>(null);

    const handleClose = () => setShow(false);
    const handleShow = (index: number) => {
        setRobotIdx(index)
        setShow(true)
    };

    return (
        <>
            <Header />

            <div className={css`
                position:relative;
                display:flex;
                justify-content:center;
            `}>
                <img src="/images/home_header_bk.png" alt="" className={css`
                    position: absolute;
                    z-index: -1;
                    width: 100%;
                    max-width:1440px;
                `} />
            </div>

            <HomeHeader />

            <div ref={scrollRef} />

            <Container className={css`
                display:flex;
                flex-direction:column;
                gap:90px;
                margin-top:90px;
                @media (max-width:990px){
                    gap:30px;
                    margin-top:30px;
                }
            `}>
                {robots.map((robot, index) => {
                    return <Card color={robot.cardBk} className={css`
                        position:relative;
                        @media (max-width:990px){
                            position:unset;
                            display:flex;
                            flex-direction:column;
                            align-items:center;
                        }
                    `}>
                        <img src={`/images/robot${index}.png`} alt="" className={css`
                            position:absolute;
                            width:170px;
                            ${index === 1 ? `
                                top:-30px;
                                right:0;
                            ` : `
                                top:-30px;
                                left:0;
                            `}
                            @media (max-width:990px){
                                position:unset;
                                width:85px;
                            }
                            @media (max-width:990px){
                                ${index === 1 ? `
                                top:40px;
                                right:0;
                            ` : `
                                top:40px;
                                left:0;
                            `} 
                            }
                        `} />
                        <div className={css`
                            ${index === 1 ? `
                                padding:25px 200px 25px 100px;
                            `: `
                                padding:25px 100px 25px 200px;
                            `}
                            @media (max-width:990px){
                                padding:0;
                            }                         
                        `}>
                            <div className={css`
                                font-size:32px;
                                font-weight:600;
                                color:#323842;
                                @media (max-width:990px){
                                    font-size:24px;
                                }
                            `}>{robot.name}</div>
                            <div className={css`
                                font-size:20px;
                                margin-bottom:12px;
                                color:#323842;
                                font-weight:500;
                                @media (max-width:990px){
                                    font-size:16px;
                                }
                            `}>{robot.intro}</div>
                            <div className='d-flex justify-content-between align-items-center gap-1'>
                                <div className='d-flex align-items-center gap-2'>
                                    <img src={`/images/icon_chat${index}.png`} alt="" width={28} height={28} />
                                    <div className={css`
                                        font-size:16px;
                                        color:${robot.btnColor};
                                        font-weight:500;
                                        @media (max-width:990px){
                                            font-size:12px;
                                        }
                                    `} >{robot.text}</div>
                                </div>
                                <RobotBtn
                                    robotId={index}
                                    style={{
                                        width: 110,
                                        padding: 10,
                                        flex: '0 0 90px',
                                        height: 'fit-content'
                                    }}
                                    onClick={() => handleShow(index)}
                                >Try Me</RobotBtn>
                            </div>
                        </div>
                    </Card>
                })}

                <div />
            </Container>

            <Container style={{ marginBottom: 90 }}>
                <Row xl={4} md={2} sm={1} xs={1}>
                    {CardContent.map((item, index) => {
                        return <Col style={{ padding: '10px' }}>
                            <div className={css`
                                background:${item.bk};
                                padding:20px 10px;
                                border-radius:10px;
                                display:flex;
                                flex-direction:column;
                                align-items:center;
                                height:100%;
                                gap:10px;
                            `}>
                                <img src={`/images/home_card_img${index + 1}.png`} alt="" width={70} height={70} />
                                <p className={css`
                                    font-size:24px;
                                    font-weight:600;
                                    text-align:center;
                                    padding:0 30px;
                                `}>{item.title}</p>
                                <p className='text-center'>{item.intro}</p>
                            </div>
                        </Col>
                    })}
                </Row>
            </Container>

            <Container className='d-flex flex-column gap-3' style={{ marginBottom: 50 }}>
                <div className={css`
                    margin-bottom:50px;
                    @media (max-width:990px){
                        margin-bottom:0;
                    }
                `}>
                    <TitleLine className='mb-3'>Trusted brands partnership</TitleLine>
                </div>

                <div style={{ background: '#fafafb', borderRadius: 20, border: '1px #dee1e680 solid' }}>
                    <img src="/images/trusted_banner.png" alt="" style={{ width: '100%' }} />
                </div>
            </Container>

            <Container>
                <HomeAsideBox>
                    <div className={css`
                        position:relative;
                        padding:100px 20px 20px;
                    `}>
                        <img src="/images/icon_double_marks.png" alt="" className={css`
                            width:100px;
                            position:absolute;
                            top:0;
                            left:0;
                        `} />
                        <img src="/images/icon_double_marks.png" alt="" className={css`
                            width:100px;
                            position:absolute;
                            bottom:0;
                            right:0;
                            transform:scaleY(-1) scaleX(-1);
                        `} />
                        <p className={css`
                            font-size:32px;
                            font-weight:600;
                            @media(max-width:990px){
                                font-size:24px;
                            }
                        `}>Experience a fast and effortless journey to access energy.</p>
                        <p className={css`
                            font-size:20px;
                            margin-bottom:20px;
                            @media(max-width:990px){
                                font-size:18px;
                            }
                        `}>Energrus helps you to find the energy plan best suit you in a few easy steps</p>
                        <MyButton
                            bkcolor='#00bdd6'
                            style={{ width: 160 }}
                            onClick={() => {
                                scrollRef?.current?.scrollIntoView({ behavior: 'smooth' })
                            }}
                        >Try Today</MyButton>
                    </div>
                    <img src="/images/step_img.png" alt="" className='img-aside' />
                </HomeAsideBox>
            </Container>

            <Container>
                <div className={css`
                    display:flex;
                    justify-content:space-between;
                    padding:50px 0;

                    @media(max-width:990px){
                        flex-direction:column;
                        padding:20px 0;
                    }
                `}>
                    <div>
                        <p className={css`
                            font-size:32px;
                            font-weight:600;
                            @media(max-width:990px){
                                font-size:24px;
                            }
                        `}>Popular energy reads just for you!</p>
                        <p className={css`
                            font-size:20px;
                            margin-bottom:0px;
                            @media(max-width:990px){
                                font-size:18px;
                            }
                        `}>Including our favorite to help you along the way.</p>
                    </div>
                    <div className={css`
                        display:flex;
                        align-items:center;
                        gap:10px;
                        cursor:pointer;
                        @media(max-width:990px){
                            justify-content:end;
                        }
                    `}>
                        <img src="/images/icon_arrow_right.png" alt="" className={css`
                            width:20px;
                        `} />
                        <div className={css`
                            color:#00BDD6FF;
                            font-size:20px;
                            font-weight:600;
                            cursor:pointer;
                            @media(max-width:990px){
                                font-size:16px;
                            }
                        `}>READ MORE</div>
                    </div>
                </div>

                <Card color='#fafafb' border bColor='#DEE1E680' style={{ margin: '20px 0' }}>
                    <HomeAsideBox>
                        <img src="/images/blog_img1.png" alt="" className='img-aside' />
                        <div className='d-flex flex-column justify-content-between gap-4'>
                            <div className='d-flex gap-3 flex-column'>
                                <div className={css`
                                    font-size:20px;
                                    color:#17A948FF;
                                    font-weight:600;
                                `}>GREEN ENERGY</div>
                                <div className={css`
                                    font-size:32px;
                                    color:#171A1FFF;
                                    font-weight:600;
                                `}>How green energy plan work</div>
                                <div className={css`
                                    font-size:14px;
                                    color:#9095A0FF;
                                `}>ApriI 25 ， 2025</div>
                                <div className={css`
                                    color:#171A1FFF;
                                `}>A green energy plan, also known as a renewable energy plan or a
                                    clean energy plan, is a strategy aimed at promoting the use of
                                    sustainable and environmentally friendly energy sources. The
                                    primary goal is to reduce dependence on fossil fuels, minimize
                                    greenhouse gas emissions, and mitigate climate change</div>
                            </div>

                            <div className='d-flex align-items-center gap-2'>
                                <div className={css`
                                    color:#00BDD6FF;
                                    font-size:18px;
                                    font-weight:600;
                                    cursor:pointer;
                                `}>Read now</div>
                                <img src="/images/icon_arrow_right.png" alt="" height={28} />
                            </div>
                        </div>
                    </HomeAsideBox>
                </Card>

                <Row md={2} xs={1}>
                    {Array.from({ length: 4 }).map((_, index) => {
                        return <Col style={{ padding: '10px' }} key={index}>
                            <Card border>
                                <div className='d-flex flex-column gap-3 justify-content-between' style={{ height: '100%' }}>
                                    <div className='d-flex flex-column gap-3'>
                                        <img src="/images/blog_img2.png" alt="" style={{ width: '100%' }} />
                                        <CardTitleLine>Start Electricity Service</CardTitleLine>
                                        {Math.random() < .5 ? <CardIntro>Start electricity service with ComparePower. Get IJOIJF service when
                                            IJOIJ need it. FolIow these steps tO sta rt electric service when
                                            moving tO a new hOUSe, apartment, condo, etc. in Texas.</CardIntro> : <CardIntro>Switch ElectricitlJ Providers Let's get straight tO the point. YOU're
                                                here tO switch electricity companies and save money on lJOlJt-
                                                energy bill, and that's exactly what  help YOIJ d0. NO gimmicks,
                                                jUSt honest guidance tO ensure IJOU make the most informed
                                                decision f0 r lJ01Jr household's energy needs. Gone 0 re the days 0f
                                                sifting through..</CardIntro>}
                                    </div>
                                    <div className='d-flex align-items-center gap-2'>
                                        <div className={css`
                                            color:#00BDD6FF;
                                            font-size:18px;
                                            font-weight:600;
                                            cursor:pointer;
                                        `}>Read now</div>
                                        <img src="/images/icon_arrow_right.png" alt="" width={28} />
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    })}
                </Row>


                <div className='d-flex justify-content-center'>
                    <p style={{
                        background: '#f3f4f6',
                        color: '#00bdd6',
                        padding: '10px 40px',
                        margin: 20,
                        cursor: 'pointer'
                    }}>See All Reviews</p>
                </div>
            </Container>

            <Container>
                <div className={css`
                    display:flex;
                    gap:30px;
                    align-items:center;
                    background:#f5feff;
                    padding:20px;
                    margin:80px 0 100px;

                    @media (max-width:990px){
                        flex-direction:column;
                        margin:20px 0;
                    }
                `} >
                    <div style={{ padding: 20 }}>
                        <img src="/images/aboutus_img.png" alt="" className={css`
                            height:300px;
                            @media(max-width:990px){
                                height:auto;
                                width:100%;
                            }
                        `} />
                    </div>
                    <div className={css`
                        padding:20px;
                    `}>
                        <p className={css`
                            font-size:32px;
                            font-weight:600;
                            @media(max-width:990px){
                                font-size:24px;
                            }
                        `}>About Us</p>
                        <p className={css`
                            font-size:20px;
                            margin-bottom:20px;
                            @media(max-width:990px){
                                font-size:16px;
                            }
                        `}>At EnergyRus, we understand that navigating through the complexities
                            of electricity plans can be overwhelming. That's why we've developed
                            the most advanced Al engine in the industry. Our Al engine analyzes
                            vast amounts of data to ensure you receive personalized
                            recommendations that align with your energy consumption patterns,
                            preferences, and budget. Say goodbye to confusing plans and hello to
                            simplified and tailored options.</p>
                    </div>
                </div>
            </Container>

            <Footer />

            <Modal show={show} onHide={handleClose} size='lg' id='font-family-fix'>
                <Modal.Header closeButton style={{ borderBottom: 'none', padding: '24px 36px 0' }}>
                </Modal.Header>

                <Modal.Body>
                    <SearchAddressInner>
                        <div className='home-modal-title'>We are almost there!</div>
                        <div className='home-modal-text' >Estimate Usage with your house size, local weather and neighborhood historical data.</div>
                        <ValidateAddress robotIdx={robotIdx} />
                    </SearchAddressInner>
                </Modal.Body>
            </Modal>
        </>
    )
}
