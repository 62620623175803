import { css } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t } from 'i18next'
import moment from 'moment'
import qs from 'qs'
import { useEffect, useState } from 'react'
import { Container, Modal, Row } from 'react-bootstrap'
import { useCookies } from 'react-cookie'
import NumberFormat from 'react-number-format'
import { useLocation, useNavigate } from 'react-router-dom'
import { fetchDailyUsage, fetchEstUsage, fetchIntervalUsage, fetchPlans, fetchPremiseDetail, getOpenAiChat, getPlanAvgPrice } from '../../api/api'
import { useUpdateEffect } from '../../common/hooks/myHooks'
import RegularLocalStorage from '../../common/hooks/useRegularLocalStorage'
import Footer from '../../components/footer'
import Header from '../../components/header'
import { LoadingRow } from '../../components/loading-row'
import PlanCard from '../../components/plan/PlanCard'
import { FooterWapper } from '../../components/styles/Footer.styled'
import { AllPlanBtn, Card, RobotBtn, TitleLine } from '../../components/styles/MyComponents.styled'
import { MonthlyBreakdownTable } from '../../plans/monthly-breakdown-table'
import { Address, MonthlyUsageProps, PlanProps, PremiseInfo, SmtDailyReads, SmtIntervalReads } from '../../types'
import LoadingPage from './LoadingPage'
import { AuroraBar, MyCol, PennyBar, TempoBar } from './robot-row'

const robot = [{
    name: 'Penny',
    intro: `I'm Penny,`,
    text: `I have optimized the following 3 options for you based on your household electricity consumption.`,
    planColor: '#00454f',
    apbk: "#eefdff",
    apbtn: "#00bdd6",
}, {
    name: 'Aurora',
    intro: `I'm Aurora,`,
    text: `I have optimized the following 3 options for you based on your household electricity consumption.`,
    planColor: '#117b34',
    apbk: "#f0fdf3",
    apbtn: "#17a948",
}, {
    name: 'Tempo',
    intro: `I'm Tempo,`,
    text: `I have optimized the following 3 options for you based on your household electricity consumption.`,
    planColor: '#96460d',
    apbk: "#fdf6f1",
    apbtn: "#ed7d2d",
}]

interface TimeStorageProps {
    expireAt: number,
    obj: any,
    verifyItem: string,
    planCount: number
}

const SuggestPlans = () => {
    const location = useLocation()
    const navigate = useNavigate();
    const idx = parseInt(location.pathname.split('/').pop() || '0');

    const [address, setAddress] = useState<Address>({} as Address);
    const [plans, setPlans] = useState<Array<PlanProps>>([])
    const [sgPlans, setSgPlans] = useState<Array<PlanProps>>([])
    const [usage, setUsage] = useState([]);
    const [premiseDetail, setPremiseDetail] = useState<PremiseInfo>({} as PremiseInfo)
    const [totalUsage, setTotalUsage] = useState<number>(0)
    const [freeWeekendUsage, setFreeWeekendUsage] = useState(undefined)
    const [free7DaysUsage, setFree7DaysUsage] = useState(undefined)
    const [freeNightUsage, setFreeNightUsage] = useState(undefined)
    const [consentId, setConsentId] = useState('')
    const [cookies, setCookie, removeCookie] = useCookies(['consent_id', 'actual_usage', 'sgplan0', 'sgplan1', 'sgplan2'])
    const [planCount, setPlanCount] = useState(0)
    const [chatId, setChatId] = useState<string>('')
    const [planAvgPrices, setPlanAvgPrices] = useState({})
    const [dayUsePresent, setDayUsePresent] = useState(0.7)

    const { address: street, zipcode, city, state, esiid, utcode: utCode, actualusage } = qs.parse(
        location.search,
        { ignoreQueryPrefix: true }
    )

    const [message, setMessage] = useState<string>();
    const [chatLoading, setChatLoading] = useState(false);
    const [isDefaultClicked, setIsDefaultClicked] = useState(false)

    const [showLoadingPage, setShowLoadingPage] = useState(false);
    const [showChatModal, setShowChatModal] = useState(false);
    const [showPlanDetailModal, SetShowPlanDetailModal] = useState(false);
    const [detailPlanId, SetDetailPlanId] = useState(0);
    const [firstPart, setFirstPart] = useState<string>()
    const [secondPart, setSecondPart] = useState<string>()
    const [growNum, setGrowNum] = useState(0)

    const handleCloseChatModal = () => setShowChatModal(false);
    const handleClosePlanDetailModal = () => SetShowPlanDetailModal(false);

    useEffect(() => {
        if (cookies && cookies.consent_id) {
            setConsentId(cookies.consent_id)
        } else {
            setConsentId('')
        }

        setAddress({ street, city, state, zipcode, esiid } as Address)

        idx === 0 && getPlanAvgPrice(street, zipcode).then((res) => {
            if (res && parseInt(res.status) === 1) {
                const { average_price } = res.data
                if (Object.keys(average_price).length > 0) {
                    const average_price_0 = average_price[Object.keys(average_price)[0]]
                    let avgPriceObj: any = {}
                    for (let i in average_price_0) {
                        avgPriceObj[average_price_0[i].term] = average_price_0[i].average_rate
                    }
                    setPlanAvgPrices(avgPriceObj)
                }
            }
        })
    }, []);

    useEffect(() => {
        if (!address.zipcode) return;
        let acUsage = localStorage.getItem("actualusage") || actualusage

        !!acUsage ? setActualUsage(acUsage) : setEstUsage()

        let premiseDetail = RegularLocalStorage(`premiseDetail`, localStorage.getItem('saved_address') || '', getPremiseDetail)
        premiseDetail && setPremiseDetail(premiseDetail)
    }, [address])


    const setActualUsage = (usage: any) => {
        const actualUsageParams = usage.toString().split('-')
        for (let i in actualUsageParams) {
            actualUsageParams[i] = parseInt(actualUsageParams[i])
        }
        setUsage(actualUsageParams)
    }

    const setEstUsage = () => {
        let estUsage = RegularLocalStorage(`estusage`, localStorage.getItem('saved_address') || '', getEstUsage)
        estUsage && setUsage(Object.values(estUsage))
    }

    const getEstUsage = () => {
        fetchEstUsage(street, city, state, zipcode).then((res) => {
            if (res && res.status === 1 && res.usages) {
                setUsage(Object.values(res.usages));
                let estusage: TimeStorageProps = { expireAt: Date.now() + 600000, obj: res.usages, verifyItem: address.street, planCount: growNum }
                localStorage.setItem('estusage', JSON.stringify(estusage))
            }
        })
    }

    const getPremiseDetail = () => {
        fetchPremiseDetail(zipcode, street).then((res) => {
            if (res.status === 1) {
                setPremiseDetail(res.premise)
                let premiseDetail: TimeStorageProps = { expireAt: Date.now() + 600000, obj: res.premise, verifyItem: address.street, planCount: growNum }
                localStorage.setItem('premiseDetail', JSON.stringify(premiseDetail))
            }
        })
    }

    useEffect(() => {
        if (!address.zipcode) return;
        let total = 0;
        usage.forEach((item: any) => {
            if (typeof item === 'string') {
                item = parseInt(item)
            }
            total += item;
        })
        setTotalUsage(total)

        let sgplan = RegularLocalStorage(`sgplan${idx}`, localStorage.getItem('saved_address') || '', fetchPlan)

        if (sgplan) {
            setSgPlans(sgplan)
            let obj = JSON.parse(localStorage.getItem(`sgplan${idx}`) || '')
            if (obj) {
                setGrowNum(obj.planCount || 0)
            }
        }
    }, [usage])

    const fetchPlan = () => {
        if (idx === 0) {
            fetchPlans('normal', usage, zipcode, null, utCode).then((res) => {
                if (res && res.response && res.response.plans) {
                    setPlans(res.response.plans)
                    setPlanCount(res.response.plans.length)
                }
            })
        }
        if (idx === 1) {
            fetchPlans('normal', usage, zipcode, { renewable: true }, utCode).then((res) => {
                if (res && res.response && res.response.plans) {
                    setPlans(res.response.plans)
                    setPlanCount(res.response.plans.length)
                }
            })
        }
        if (idx === 2) {
            getDailyUsage()
            getIntervalUsage()
        }
    }

    useEffect(() => {
        if (!freeWeekendUsage || !free7DaysUsage || !freeNightUsage) return
        getTouPlans()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usage, freeWeekendUsage, free7DaysUsage, freeNightUsage])

    useEffect(() => {
        const timer = setTimeout(() => {
            if (sgPlans?.length > 0) {
                setShowLoadingPage(false);
            }
        }, 3000);

        return () => clearTimeout(timer);
    }, [sgPlans]);

    const getTouPlans = async () => {
        setPlans([])
        let plans: Array<PlanProps> = []
        let count = 0

        await fetchPlans('night', freeNightUsage, zipcode, null, `${utCode}`).then(
            (res) => {
                if (res && res.response && res.response.plans) {
                    plans = plans.concat(res.response.plans)
                    count += res.response.res_count
                }
            }
        )
        await fetchPlans('weekend', freeWeekendUsage, zipcode, null, `${utCode}`).then(
            (res) => {
                if (res && res.response && res.response.plans) {
                    plans = plans.concat(res.response.plans)
                    count += res.response.res_count
                }
            }
        )
        await fetchPlans('7days', free7DaysUsage, zipcode, null, `${utCode}`).then(
            (res) => {
                if (res && res.response && res.response.plans) {
                    plans = plans.concat(res.response.plans)
                    count += res.response.res_count
                }
            }
        )
        setPlans(plans)
        setPlanCount(count)
    }

    useEffect(() => {
        let sgplan = RegularLocalStorage(`sgplan${idx}`, localStorage.getItem('saved_address') || '', () => { })
        !sgplan && setShowLoadingPage(true)
    }, [idx])

    const getDailyUsage = () => {
        setFreeWeekendUsage(undefined)
        setFree7DaysUsage(undefined)
        if (consentId) {
            const startDate = moment().subtract(1, 'years').format('MM/DD/YYYY')
            const endDate = moment().subtract(1, 'days').format('MM/DD/YYYY')
            fetchDailyUsage(esiid, consentId, startDate, endDate).then((res) => {
                if (res && res.response && res.response.reads) {
                    const free7DaysAndWeekendUsage = mergeDailyReads(res.response.reads)
                    setFree7DaysUsage(free7DaysAndWeekendUsage.free7Days)
                    setFreeWeekendUsage(free7DaysAndWeekendUsage.freeWeekend)
                }
            })
        } else {
            setFree7DaysUsage(EstUsage(usage) as any)
            setFreeWeekendUsage(EstUsage(usage) as any)
        }
    }

    const getIntervalUsage = () => {
        setFreeNightUsage(undefined)
        if (consentId) {
            const startDate = moment().subtract(1, 'years').format('MM/DD/YYYY')
            const endDate = moment().subtract(1, 'days').format('MM/DD/YYYY')
            fetchIntervalUsage(esiid, consentId, startDate, endDate).then((res) => {
                if (res && res.response && res.response.reads) {
                    const dayNightUsage = mergeIntervalReads(res.response.reads)
                    setFreeNightUsage(dayNightUsage)
                }
            })
        } else {
            setFreeNightUsage(EstUsage(usage) as any)
        }
    }

    const mergeDailyReads = (data: Array<SmtDailyReads>) => {
        let freeWeekend: any = {},
            free7Days: any = {},
            dailyUsage: any = []

        for (let i in data) {
            const date = moment(data[i].read_date, 'MM/DD/YYYY')
            const m = date.format('M')
            const w = date.days()
            const objKey = w === 0 || w === 6 ? 'free' : 'charge'
            const usage = parseFloat(data[i].energy_data_kwh)

            if (!dailyUsage[m]) dailyUsage[m] = []
            if (!freeWeekend[m]) freeWeekend[m] = {}

            dailyUsage[m].push(usage)
            freeWeekend[m][objKey] = freeWeekend[m][objKey] ? freeWeekend[m][objKey] + usage : usage
        }

        for (let m in dailyUsage) {
            const oneMonth = dailyUsage[m]
            oneMonth.sort((a: string, b: string) => {
                if (parseInt(a) < parseInt(b)) return 1
                if (parseInt(a) > parseInt(b)) return -1
                return 0
            })
            oneMonth.forEach((val: number, idx: number) => {
                const objKey = idx < 7 ? 'free' : 'charge'
                if (!free7Days[m]) free7Days[m] = {}
                free7Days[m][objKey] = free7Days[m][objKey] ? free7Days[m][objKey] + val : val
            })
        }

        return { freeWeekend: freeWeekend, free7Days: free7Days }
    }

    const EstUsage = (estUsage: Array<number>) => {
        const newObj: any = {}
        for (const [key, value] of Object.entries(estUsage)) {
            newObj[key] = { free: value * .3, charge: value * dayUsePresent }
        }
        return newObj
    }

    const mergeIntervalReads = (data: Array<SmtIntervalReads>) => {
        let freeNight: any = {}
        for (let i in data) {
            const datetime = moment(data[i].datetime, 'MM/DD/YYYY HH:mm')
            const date = datetime.format('MM/DD/YYYY')
            const m = datetime.format('M')

            const objKey =
                datetime.isSameOrAfter(`${date} 20:00`, 'minute') ||
                    datetime.isBefore(`${date} 05:59`, 'minute')
                    ? 'free'
                    : 'charge'
            if (!freeNight[m]) freeNight[m] = {}
            freeNight[m][objKey] = freeNight[m][objKey]
                ? freeNight[m][objKey] + parseFloat(data[i].usage)
                : parseFloat(data[i].usage)
        }

        return freeNight
    }

    useUpdateEffect(() => {
        if (plans.length < 1) {
            return;
        }

        let _plans = plans
        if (idx !== 2) {
            _plans = _plans.filter((item) => { return item.type === 'broker' })
        }
        console.log(_plans);


        const sgPlans = [
            _plans.find((item) => item.contract_term === '12'),
            _plans.find((item) => item.contract_term === '24'),
            _plans.find((item) => item.contract_term === '36'),
        ];

        setSgPlans(sgPlans as any);
        let storagePlan: TimeStorageProps = { expireAt: Date.now() + 600000, obj: sgPlans, verifyItem: address.street, planCount: growNum }
        localStorage.setItem(`sgplan${idx}`, JSON.stringify(storagePlan))
    }, [plans])

    useEffect(() => {
        if (!message) {
            return
        }
        const firstLineBreak = message.indexOf('\n');
        const firstPart = message.slice(0, firstLineBreak);
        const secondPart = message.slice(firstLineBreak + 1);
        setFirstPart(firstPart)
        setSecondPart(secondPart)
    }, [message]);

    useUpdateEffect(() => {
        setSgPlans([])
        getDailyUsage()
        getIntervalUsage()
    }, [dayUsePresent])

    const redirectTo = () => {
        const { street, esiid, zipcode, city, state } = address;

        let params = `?address=${encodeURIComponent(`${street}`)}&esiid=${esiid}&zipcode=${zipcode}&city=${(encodeURIComponent(`${city}`))}&state=${state}&utcode=${utCode}`;
        if (cookies && cookies.actual_usage) {
            params += `&usagefrom=smt&actualusage=${cookies.actual_usage}`;
        }
        navigate(`/plans/${idx}${params} `);
    }

    const sendMessage = (text: string, clickDf: boolean) => {
        setChatLoading(true);
        getOpenAiChat(text, chatId).then((res) => {
            if (res.status === 1) {
                setMessage(res.response.answer);
                setChatId(res.response.chat_id)
            }
            setChatLoading(false);
        }).catch(() => {
            setMessage("Sorry, I don't understand your question. Please try again.");
            setChatLoading(false);
        })
    }

    const handleClickDefault = () => {
        let defaultTxt = `I want you to act as bot name ${robot[idx].name} as sustainability energy advisor,
        that focuses on sustainability and environmentally friendly plans.
        ${robot[idx].name} is a forward-thinking and eco-conscious bot.
        She's passionate about helping customers reduce their carbon footprint and find electricity plans that are sustainable and affordable.
        ${robot[idx].name} is knowledgeable about green energy options and is always eager to share her expertise.
        ${premiseDetail?.detail?.bedrooms ? `Below is the customer home info ${totalUsage}kwh annual usage with house square footage ${premiseDetail?.detail?.lot_size},${premiseDetail?.detail?.bedrooms} bedrooms and ${premiseDetail?.detail?.bathrooms} bathrooms.` : ''}
        Found higest green percetage and lowest 3 plans out of total ${planCount} plans.\n
        ${sgPlans.map((item, index) => `Plan ${index + 1} - your price is ${item?.rate} cent with ${item?.contract_term} month term with ${item?.renewable}% green\n`).join('')}`
        console.log(sgPlans);

        if (!isDefaultClicked) {
            !chatLoading && sendMessage(defaultTxt, true);
            setIsDefaultClicked(true)
        }
        setShowChatModal(true)
    }

    const usageToObj = (usage: Array<number>) => {
        return usage.reduce((acc: MonthlyUsageProps, curr, index) => {
            acc[index + 1] = curr.toString();
            return acc;
        }, {});
    }

    const ShowDetailPlan = (val: number) => {
        SetDetailPlanId(val)
        SetShowPlanDetailModal(true)
    }

    if (showLoadingPage) {
        return <LoadingPage setGrowNum={setGrowNum} />
    }

    return (
        <>
            <FooterWapper>
                <Header />

                <Container className={css`
                    margin-top:50px;
                    @media (max-width:990px){
                        margin-top:15px;
                        padding:0 25px;
                    }
                `}>
                    {idx === 0 && <PennyBar usage={usage} premiseDetail={premiseDetail} totalUsage={totalUsage} />}
                    {idx === 1 && <AuroraBar premiseDetail={premiseDetail} totalUsage={totalUsage} />}
                    {idx === 2 && <TempoBar usage={usage} premiseDetail={premiseDetail} totalUsage={totalUsage} setDayUsePresent={setDayUsePresent} />}
                </Container>

                <Container>
                    <div className={css`
                        display:flex;
                        gap:40px;
                        margin:40px 0;
                        @media (max-width:768px){
                            flex-direction:column;
                        }
                    `}>
                        <div className={css`
                            display:flex;
                            gap:50px;
                            @media (max-width:990px){
                                gap:25px;
                            }
                        `}>
                            <div className={css`
                                flex:0 0 120px;
                                @media(max-width:687px){
                                    flex:0 0 70px;
                                }
                            `}>
                                <img src={`/images/robot${idx}.png`} alt="" className={css`
                                    width:100%;
                                    ${idx === 1 && `transform:rotateY(180deg)`}
                                `} />
                            </div>
                            <div className='mt-3'>
                                <Card color={robot[idx].apbk} triangle >
                                    <p className={css`
                                        margin-bottom:0;
                                        @media (max-width:768px){
                                            font-size:14px;
                                        }
                                    `}>
                                        {idx === 0 && `I am Penny, your cost-saving energy advisor. I'm happy to assist you in finding the most cost-effective electricity plans based on your usage and preferences.Based on your annual usage of ${totalUsage}kwh and a house square footage of ${premiseDetail?.detail?.lot_size} with ${premiseDetail?.detail?.bedrooms} bedrooms and ${premiseDetail?.detail?.bathrooms} bathrooms, I have identified the best plan for you that is the most cost-effective options available in your area.`}
                                        {idx === 1 && `Hello, I'm Aurora, your sustainability energy advisor. I'm happy to assist you in finding environmentally friendly electricity plans that can also save you money. With your ${totalUsage}kwh annual usage and a house square footage of ${premiseDetail?.detail?.lot_size}, with ${premiseDetail?.detail?.bedrooms} bedrooms and ${premiseDetail?.detail?.bathrooms} bathrooms, I've found the top 3 plans for you based on your preferences.`}
                                        {idx === 2 && `Hello, I'm Tempo, your personal energy advisor! I specialize in Time-of-Use (TOU) electricity plans, which can help you save even moreby taking advantage of free electricity during off-peak hours.Based on your annual usage of ${totalUsage}kwh and a house square footage of ${premiseDetail?.detail?.lot_size} with ${premiseDetail?.detail?.bedrooms} bedrooms and ${premiseDetail?.detail?.bathrooms} bathrooms, I have identified the best plan for you that is the most cost-effective option available in your area.Use night vs day usage analyzer scroll bar to find out how you may optimize your benefit using TOU plans.`}
                                    </p>
                                    <RobotBtn
                                        robotId={idx}
                                        style={{
                                            width: 'fit-content',
                                            padding: '10px 20px',
                                            marginTop: 15
                                        }}
                                        onClick={handleClickDefault}
                                    >Tell me more</RobotBtn>
                                </Card>
                            </div>
                        </div>
                        <div className='d-flex justify-content-around gap-4'>
                            {robot.map((item, index) => {
                                if (idx === index) return

                                return <div key={index} style={{
                                    position: 'relative',
                                    cursor: 'pointer',
                                    minHeight: 190,
                                }}
                                    onClick={() => {
                                        navigate(`/${location.pathname.split('/')[1]}/${index}${location.search}`, { replace: true })
                                        window.location.reload()
                                    }}
                                >
                                    <img src={`/images/robot${index}.png`} alt="" width={90} style={{ display: 'flex', alignItems: 'center' } && (idx === 1 ? { transform: 'rotateY(180deg)' } : {})} />
                                    <RobotBtn
                                        robotId={index}
                                        style={{
                                            padding: 5,
                                            position: 'absolute',
                                            top: 143,
                                            left: 0,
                                            zIndex: -1,
                                        }}
                                    >Try Me</RobotBtn>
                                </div>
                            })}
                        </div>
                    </div>
                </Container>

                <Container className='d-flex gap-3 flex-column mt-3'>
                    <TitleLine >{`Plans Recommended by ${robot[idx].name}`}</TitleLine>
                    <div className={css`
                        font-size:24px;
                        font-wight:500;
                        text-align:center;
                        margin-bottom:40px;

                        @media (max-width:990px){
                            font-size:18px;
                        }
                    `}>{`From ${growNum} plans available in ${address.street}`}</div>

                    <Row>
                        {sgPlans.length < 1 && <LoadingRow />}
                        {sgPlans.map((plan, index) => {
                            if (index > 2) return null;
                            return <MyCol lg={4} key={index}>
                                <PlanCard robot={idx}
                                    index={index}
                                    planColor={robot[idx].planColor}
                                    plan={plan}
                                    ShowDetailPlan={ShowDetailPlan}
                                    avgPrice={
                                        (planAvgPrices as any)[`${plan.contract_term}`]
                                            ? (planAvgPrices as any)[
                                            `${plan.contract_term}`
                                            ]
                                            : '0'
                                    }
                                />
                            </MyCol>
                        })}
                    </Row>
                </Container>

                <Container className={css`
                    margin-top:50px;
                    @media (max-width:990px){
                        margin-top:20px;
                    }
                `}>
                    <div className={css`
                        padding: 22px;
                        border-radius: 8px;
                        position:relative;
                        background-color:${robot[idx].apbk};
                        height:240px;
                        z-index:1;
                        margin:50px 0;
                        @media (max-width:990px){
                            height:120px;
                        }
                    `}>
                        <div className={css`
                            z-index:999;
                            display:flex;
                            flex-direction:column;
                            margin-left:15px;
                            height:100%;
                            justify-content:center;
                            padding-right:40%;
                        `}>
                            <p className={css`
                                font-size: 32px;
                                font-weight: 600;
                                color:#333842;
                                max-width: 500px;
                                background: ${robot[idx].apbk}af;
                                @media(max-width:990px){
                                    font-size:18px;
                                }
                            `}>Not sure which plan to choose?</p>
                            <AllPlanBtn
                                robotId={idx}
                                onClick={redirectTo}
                            >Show All Plans</AllPlanBtn>
                        </div>
                        <img src="/images/planbg.png" alt=""
                            className={css`
                            position:absolute;
                            top:-46px;
                            right:90px;
                            z-index:-1;
                            @media (max-width:990px){
                                width:120px;
                                top:-18px;
                                right:15px;
                            }
                        `} />
                        <img src={`/images/icon_folder${idx}.png`} alt=""
                            className={css`
                            position:absolute;
                            width:58px;
                            top:80px;
                            right:70px;
                            z-index:-1;
                            @media (max-width:990px){
                                width:25px;
                                top:40px;
                                right:7px;
                            }
                        `} />
                    </div>
                </Container>

                <Footer />
            </FooterWapper >

            <Modal show={showChatModal} onHide={handleCloseChatModal} size='lg' id='font-family-fix'>
                <Modal.Header closeButton style={{ borderBottom: 'none', padding: '24px 36px 0' }}>
                </Modal.Header>

                <Modal.Body>
                    <div className={css`
                        padding:10px 70px 70px;
                        max-height:75vh;
                        overflow:auto;
                        @media (max-width:768px){
                            padding:10px;
                            font-size:14px;
                        }
                    `}>
                        {message && <div className={css`
                                    display: flex;
                                    flex-direction: column;
                                    gap: 10px;
                                `}>
                            <div className='d-flex gap-2'>
                                <div>
                                    <img src={`/images/robot${idx}.png`} alt="" className={css`
                                    width:100px;
                                    @media (max-width:768px){
                                        width:75px;
                                    }
                                `} />
                                </div>
                                <div className={css`
                                    background: ${robot[idx].apbk};
                                    padding: 10px;
                                    height: fit-content;
                                    background:${robot[idx].apbk};
                                    }
                                `} dangerouslySetInnerHTML={{ __html: String.raw`${firstPart}` }}></div>
                            </div>

                            <div >
                                <p style={{
                                    whiteSpace: 'pre-wrap',
                                    marginBottom: 0
                                }} dangerouslySetInnerHTML={{ __html: String.raw`${secondPart}` }} />
                            </div>
                        </div >}

                        {chatLoading && <LoadingRow />}

                        {/* {!chatLoading && <div className={css`
                                    display: flex;
                                `}>
                            <span>
                                <img src={`/images/robot${idx}.png`} alt="" width={67} />
                            </span>
                            <div className={css`
                                background: #f3f4f6;
                                padding: 10px;
                                height: fit-content;
                                margin-left:15px;
                                background:#eefdff;
                                }
                            `}>
                                <LoadingRow />
                            </div>
                        </div >} */}
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={showPlanDetailModal} onHide={handleClosePlanDetailModal} size='lg' id='font-family-fix'>
                <Modal.Header closeButton style={{ borderBottom: 'none', padding: '24px 36px 0' }}>
                </Modal.Header>

                <Modal.Body >
                    {sgPlans.length > 0 &&
                        <div className={`plan-detail active`}>
                            <div className="pb-4">
                                <div className="plan-detail-heading">{t('Estimated Annualized Cost')}</div>
                                <div className="mb-2">
                                    {t('Total Est. Cost')}: <NumberFormat value={sgPlans[detailPlanId].total} displayType={'text'} thousandSeparator={true} prefix="$" /> ({t('Based on')} <NumberFormat value={sgPlans[detailPlanId].total_usage} displayType={'text'} suffix={'kWh'} thousandSeparator={true} />)
                                </div>
                                <MonthlyBreakdownTable usage={usageToObj(usage)} monthlyPrice={sgPlans[detailPlanId].month} hidePrice={false} />
                                <div className="plan-detail-heading">{t('Plan Details')}</div>
                                <div>{sgPlans[detailPlanId].plan_desc}</div>
                                <div className="mt-4">
                                    <div>{t('Renewable Percentage')}: {sgPlans[detailPlanId].renewable}%</div>
                                </div>
                                <div className="plan-links mt-3">
                                    <a href={sgPlans[detailPlanId].terms_url} target="_blank" rel="noreferrer"><FontAwesomeIcon icon="file-pdf" /><span className="ms-2">{t('Terms of Service')}</span></a>
                                    <a href={sgPlans[detailPlanId].efl_url} target="_blank" rel="noreferrer"><FontAwesomeIcon icon="file-pdf" /><span className="ms-2">{t('Facts Label')}</span></a>
                                    <a href={sgPlans[detailPlanId].yrac} target="_blank" rel="noreferrer"><FontAwesomeIcon icon="file-pdf" /><span className="ms-2">YRAC</span></a>
                                </div>
                            </div>
                        </div>}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SuggestPlans