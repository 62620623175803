import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { SmtGetInfo } from './via_smt/get_info';
import { SmtShowAgreement } from './via_smt/show_agreement';
import { MyAccountInfo } from './via_myaccount/get_info';
import { TduGetInfo } from './via_tdu/get_info';
import { ShowUsage } from './show_usage';
import type { GetUsageComponentProps } from '../../types';
import { fetchUtility } from '../../api/api';
import { LoadingRow } from '../loading-row';

enum PageView {
    SOURCE,
    SMTINFO,
    SMTAGREEMENT,
    TDUINFO,
    MYACCOUNTINFO,
    USAGE
}

export const GetUsageComponent: React.FC<GetUsageComponentProps> = ({
    handleSetConsentId,
    defaultView,
    inModal,
    zipcode,
    forcastUsage,
    esiid,
}) => {
    const navigate = useNavigate();
    const [view, setView] = useState(PageView.SOURCE);
    const [consentId, setConsentId] = useState('');
    const [email, setEmail] = useState('');
    const [recommend, setRecommend] = useState('');
    const [usage, setUsage] = useState([]);
    const [unusualMonth, setUnusualMonth] = useState<string[]>([]);
    const [isPredictedData, setIsPredictedData] = useState(false);
    const sourceFrom = useRef('');

    useEffect(() => {
        if (defaultView) {
            if (defaultView === 'smt') {
                setView(PageView.SMTINFO);
            }
            if (defaultView === 'tdu') {
                setView(PageView.TDUINFO);
            }
            if (defaultView === 'myaccount') {
                setView(PageView.MYACCOUNTINFO);
            }
        }
    }, [defaultView])

    useEffect(() => {
        if (zipcode) {
            fetchUtility(zipcode).then(res => {
                if (res) {
                    const isOncor = res[0].utility_name.toLowerCase().indexOf('oncor') >= 0;
                    setRecommend(isOncor ? 'smt' : 'tdu');
                }
            })
        }
    }, [zipcode])

    const getView = () => {
        switch (view) {
            case PageView.SOURCE:
                return <UsageSource
                    handleClick={(type: string): void => {
                        if (type === 'smt') setView(PageView.SMTINFO);
                        if (type === 'tdu') setView(PageView.TDUINFO);
                        if (type === 'myaccount') setView(PageView.MYACCOUNTINFO);
                    }}
                    recommend={recommend}
                />;
            case PageView.SMTINFO:
                return <SmtGetInfo
                    handleShowAgreement={(concentId: string, email: string) => {
                        setConsentId(concentId);
                        setEmail(email);
                        setView(PageView.SMTAGREEMENT);
                        handleSetConsentId(concentId);
                    }}
                    esiid={esiid}
                    handleBack={() => {
                        if (defaultView) {
                            navigate(-1);
                        }
                        else {
                            setView(PageView.SOURCE);
                        }
                    }}
                    hideBack={(inModal && defaultView) ? true : false}
                />;
            case PageView.SMTAGREEMENT:
                return <SmtShowAgreement
                    consentId={consentId}
                    handleBack={
                        () => setView(PageView.SMTINFO)
                    }
                    email={email}
                    handleSetUsage={(data: any) => {
                        setUsage(data);
                        sourceFrom.current = 'smt';
                        setView(PageView.USAGE);
                        setIsPredictedData(false);
                    }}
                    handleSetUnusualMonth={(data: string[]) => setUnusualMonth(data)}
                />;
            case PageView.MYACCOUNTINFO:
                return <MyAccountInfo
                    handleShowAgreement={(concentId: string, email: string) => {
                        setConsentId(concentId);
                        setEmail(email);
                        setView(PageView.SMTAGREEMENT);
                        handleSetConsentId(concentId);
                    }}
                    handleBack={() => {
                        if (defaultView) {
                            navigate(-1);
                        }
                        else {
                            setView(PageView.SOURCE);
                        }
                    }}
                    hideBack={(inModal && defaultView) ? true : false}
                />;
            case PageView.TDUINFO:
                return <TduGetInfo
                    handleBack={() => {
                        if (defaultView) {
                            navigate(-1);
                        }
                        else {
                            setView(PageView.SOURCE);
                        }
                    }}
                    showSmtView={() => {
                        setView(PageView.SMTINFO);
                    }}
                    showMyaccountView={() => {
                        setView(PageView.MYACCOUNTINFO);
                    }}
                    continueWithForecast={() => {
                        forcastUsage();
                    }}
                    handleSetUsage={(data: any, isPredicted?: boolean) => {
                        setUsage(data);
                        sourceFrom.current = 'tdu';
                        setView(PageView.USAGE);
                        setIsPredictedData(!!isPredicted);
                    }}
                    hideBack={(inModal && defaultView) ? true : false}
                />;
            case PageView.USAGE:
                return <ShowUsage
                    source={sourceFrom.current}
                    data={usage}
                    unusualMonth={unusualMonth}
                    isPredictedData={isPredictedData}
                    showSmtView={() => {
                        setView(PageView.SMTINFO);
                    }}
                    showMyaccountView={() => {
                        setView(PageView.MYACCOUNTINFO);
                    }}
                />
            default: return null;
        }
    }

    return (
        <div>
            {getView()}
        </div>
    )
}

const UsageSource = ({ handleClick, recommend }: {
    handleClick: (type: string) => void,
    recommend: string
}) => {
    const { t } = useTranslation('usage');
    return (
        <div>
            <h2 className="mb-4">{t('Pick Your Way to Get Usage Data')}</h2>
            {
                !recommend &&
                <div className="pt-5 pb-5"><LoadingRow /></div>
            }
            {
                recommend &&
                <div id="get-usage-option-wrapper">
                    {
                        recommend === 'tdu' &&
                        <div className="get-usage-option recommend" onClick={() => { handleClick('tdu') }}>
                            <div>
                                <h5>{t('Retrieve My Actual Usage')}</h5>
                                <p>{t('Quickest way to pull usage pattern from your utility account')}</p>
                            </div>
                            <i>{t('Recommend')}</i>
                        </div>
                    }
                    <div className={`get-usage-option ${recommend === 'smt' ? 'recommend' : ''}`} onClick={() => { handleClick('myaccount') }}>
                        <div>
                            <h5>{t('MyAccount')}</h5>
                            <p>{t('Instant access of your usage history through MyAccount')}</p>
                        </div>
                        {recommend === 'smt' && <i>{t('Recommend')}</i>}
                    </div>
                    <div className="get-usage-option" onClick={() => { handleClick('smt') }}>
                        <div>
                            <h5>{t('Use Smart Meter Texas (SMT) Data')}</h5>
                            <p>{t('Instant access of your usage history through your smart meter number')}</p>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}