import React, { useState, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import qs from 'qs'
import {
    EnrollmentStepWrapper,
    EnrollmentStepFooter,
    ReviewRow,
    ReviewBlock,
    ReviewWrapper,
    ReviewDisclaimer,
    EnrollmentFormRow,
    EnrollmentFormCol,
} from '../styles/Enrollment.styled'
import { getEnrollmentRecord, confirmEnrollment } from '../../api/enrollment'
import { toast } from 'react-toastify'
import { Button } from '../styles/Button.styled'
import { Spinner } from 'react-bootstrap'
import { LoadingRow } from '../loading-row'
import moment from 'moment'
import { RollingTextLoading } from '../rolling-text-loading'
import { CheckboxItem } from '../checkbox-item'

interface Props {
    show: boolean
    enrollmentId: string
    handleContinue: Function
    handleBack: Function
    sign: string
    specialPro: {
        ssn: string
        date_of_birth: string
    }
    handleSSN: Function
}

interface EnrollmentFields {
    billing_address: string
    billing_city: string
    billing_state: string
    billing_zipcode: string
    date_of_birth: string
    email: string
    first_name: string
    last_name: string
    phone: string
    requested_date: string
    service_address: string
    service_city: string
    service_state: string
    service_type: string
    ssn: string
}

export const EnrollmentStepReview: React.FC<Props> = ({
    show,
    enrollmentId,
    handleContinue,
    handleBack,
    sign,
    specialPro,
    handleSSN,
}) => {
    const { t } = useTranslation('enrollment')
    const location = useLocation()
    const { zipcode } = qs.parse(location.search, { ignoreQueryPrefix: true })
    const [reviewData, setReviewData] = useState<EnrollmentFields | undefined>(undefined)
    const [loading, setLoading] = useState(true)
    const [submitting, setSubmitting] = useState(false)
    const [ isAgree, setIsAgree ] = useState(false);
    const [ showConsentError, setShowConsentError ] = useState(false);

    useEffect(() => {
        if (enrollmentId && show) {
            getEnrollmentRecord(enrollmentId, sign).then((res) => {
                if (res && parseInt(res.status) === 1) {
                    setReviewData(res.response)
                    setLoading(false)
                }
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show])

    const handleSubmit = () => {
        if (!isAgree) {
            setShowConsentError(true);
            return;
        }

        if (submitting) return
        setSubmitting(true)

        try {
            confirmEnrollment(enrollmentId, specialPro, sign).then((res) => {
                if (res) {
                    if (res.error_code && res.error_code === '500') {
                        setSubmitting(false)
                        toast.error(res.message || t('The enrollment request has been submitted'))
                        return
                    }
                    const { status } = res
                    if (status) {
                        const errorCode = status.split('.')[0]
                        switch (errorCode) {
                            case '0':
                            case '-1':
                                setSubmitting(false)
                                toast.error(t(res.message))
                                break
                            case '10':
                            case '20':
                            case '30':
                            case '40':
                            case '50':
                                handleSSN(specialPro)
                                handleContinue(res)
                                break
                            default:
                        }
                    }
                } else {
                    setSubmitting(false)
                    toast.error(t('An error occurred'))
                }
            })
        } catch (error) {
            setSubmitting(false)
            toast.error(t('An error occurred'))
        }
    }

    const getContent = () => {
        if (loading || !reviewData)
            return (
                <div className="pt-5 pb-5">
                    <LoadingRow />
                </div>
            )

        const {
            billing_address,
            billing_city,
            billing_state,
            billing_zipcode,
            date_of_birth,
            email,
            first_name,
            last_name,
            phone,
            requested_date,
            service_address,
            service_city,
            service_state,
            service_type,
            ssn,
        } = reviewData

        return (
            <ReviewWrapper className="position-relative">
                <h5 className="mb-5">{t('Please double check your enrollment details')}</h5>
                <ReviewBlock>
                    <h6>{t('Service Information')}</h6>
                    <ReviewRow>
                        <label>{t('Service Address')}:</label>
                        <span>{`${service_address} ${service_city} ${service_state}, ${zipcode}`}</span>
                    </ReviewRow>
                    <ReviewRow>
                        <label>{t('Billing Address')}:</label>
                        <span>{`${billing_address} ${billing_city} ${billing_state}, ${billing_zipcode}`}</span>
                    </ReviewRow>
                    <ReviewRow>
                        <label>{t('Service Type')}:</label>
                        <span>{service_type}</span>
                    </ReviewRow>
                    <ReviewRow>
                        <label>{t('Requested Date')}:</label>
                        <span>{moment(requested_date).format('MM/DD/YYYY')}</span>
                    </ReviewRow>
                </ReviewBlock>
                <ReviewBlock>
                    <h6>{t('Customer Information')}</h6>
                    <ReviewRow>
                        <label>{t('Customer Name')}:</label>
                        <span>{`${first_name} ${last_name}`}</span>
                    </ReviewRow>
                    {phone && (
                        <ReviewRow>
                            <label>{t('Phone')}:</label>
                            <span>{phone}</span>
                        </ReviewRow>
                    )}
                    <ReviewRow>
                        <label>{t('Email')}:</label>
                        <span>{email}</span>
                    </ReviewRow>
                    {moment(date_of_birth).isValid() && (
                        <ReviewRow>
                            <label>{t('Date of Birth')}:</label>
                            <span>{moment(date_of_birth).format('MM/DD/YYYY')}</span>
                        </ReviewRow>
                    )}
                    {ssn && (
                        <ReviewRow>
                            <label>{t('SSN')}:</label>
                            <span>*****{ssn.substr(5)}</span>
                        </ReviewRow>
                    )}
                </ReviewBlock>
                <EnrollmentFormRow>
                    <EnrollmentFormCol full>
                        <div className="checkbox-row">
                            <CheckboxItem
                                label={<Trans i18nKey="enrollment:consentSubmitLabel" components={[<br />, <br />]} />}
                                value={`${isAgree}`}
                                checked={isAgree}
                                handleClick={() => {
                                    setShowConsentError(false);
                                    setIsAgree(!isAgree);
                                }}
                                onlyread={false}
                                disabled={loading}
                                error={showConsentError}
                            />
                        </div>
                    </EnrollmentFormCol>
                </EnrollmentFormRow>
                <EnrollmentStepFooter>
                    <Button variant="secondary" disabled={submitting} onClick={() => handleBack()}>
                        {t('Back')}
                    </Button>
                    <Button type="button" onClick={() => handleSubmit()} data-test-id="cta">
                        <span>{t('Submit')}</span>
                        {submitting && (
                            <Spinner
                                animation="border"
                                variant="light"
                                size="sm"
                                className="ms-2"
                            />
                        )}
                    </Button>
                </EnrollmentStepFooter>
                <ReviewDisclaimer>
                    {t(
                        "After you click 'Submit', your information will be sent to the Retail Energy Provider (REP) for their review and approval. A soft check on your credit will be run and used to assist with the decision. Submit now and get your approval within a few seconds!"
                    )}
                </ReviewDisclaimer>
                <RollingTextLoading
                    text={[
                        t('This process may take upto 1 minute', { ns: 'common' }),
                        t(
                            'We are transmitting your information to the Retail Energy Provider(REP)...',
                            { ns: 'common' }
                        ),
                        t('Be patient while REP is reviewing your application', { ns: 'common' }),
                        t('REP is still verifying your information...', { ns: 'common' }),
                        t("We are waiting for REP's approval...", { ns: 'common' }),
                        t('Get the approval in a few seconds', { ns: 'common' }),
                    ]}
                    show={submitting}
                />
            </ReviewWrapper>
        )
    }

    if (!show) return null
    return <EnrollmentStepWrapper>{getContent()}</EnrollmentStepWrapper>
}
