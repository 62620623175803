import { css } from '@emotion/css';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { fetchAddress, fetchUtility } from '../api/api';
import { Button } from '../components/styles/Button.styled';
import { Address, Utilities } from '../types';

const ChooseColor = ['#00bdd6', '#17a948', '#ed7d2d']

const ValidateAddressWidget = () => {
    const [robotIdx, setRobotIdx] = useState<number>(1)
    const [loading, setLoading] = useState(false);
    const [addressOptions, setAddressOptions] = useState<Address[]>([]);
    const [selectedAddress, setSelectedAddress] = useState<Address[]>([]);
    const [loadingUtility, setLoadingUtility] = useState(false);
    const [utility, setUtility] = useState<Utilities[]>([]);
    const [selectedUtility, setSelectedUtility] = useState<Utilities | undefined>(undefined);

    useEffect(() => {
        if (selectedAddress.length === 0) return;
        setLoadingUtility(true);
        const { zipcode } = selectedAddress[0];
        fetchUtility(zipcode).then(res => {
            setLoadingUtility(false);
            if (res) {
                setUtility(res);
                setSelectedUtility(res[0]);
            }
        })
    }, [selectedAddress])

    const handleSearch = (val: string) => {
        setLoading(true);
        fetchAddress(val).then(data => {
            setAddressOptions(data);
            setLoading(false);
        })
    }

    const redirectTo = () => {
        const address = selectedAddress[0];
        window.open(`https://www.energyrus.com/suggest_plans/${robotIdx}?address=${encodeURIComponent(`${address.street}`)}&esiid=${address.esiid}&zipcode=${address.zipcode}&city=${address.city}&state=${address.state}&utcode=${utility[0].utility_code}`);
    }

    const handleImageClick = (event: any) => {
        const clickedImage = event.target;
        const altText = clickedImage.getAttribute('alt');

        if (altText === 'Penny') {
            setRobotIdx(0)
        } else if (altText === 'Aurora') {
            setRobotIdx(1)
        } else if (altText === 'Tempo') {
            setRobotIdx(2)
        }
    };

    const chooseUtility = () => {
        if (utility.length <= 1 || !selectedUtility) return null;
        return (
            <Dropdown className="utility-select">
                <Dropdown.Toggle variant="light">
                    {selectedUtility.utility_name}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {
                        utility.map((val, idx) => (
                            <Dropdown.Item
                                eventKey={idx}
                                key={idx}
                                onClick={() => {
                                    setSelectedUtility(val);
                                }}
                                active={val.utility_id === selectedUtility.utility_id}
                            >{val.utility_name}</Dropdown.Item>
                        ))
                    }
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    return (
        <div className={css`
            display:flex;
            align-items:center;
            gap:1rem;
            @media(max-width:900px){
                flex-direction:column;
            }
        `}>
            <div className={css`
                display:flex;
                gap:10px;
                
                img{
                    padding:5px;
                    height:180px;
                    cursor:pointer;
                    @media(max-width:900px){
                        height:100px;
                    }
                }

                .active{
                    border-bottom:2px solid ${ChooseColor[robotIdx]};
                    border-top:2px solid ${ChooseColor[robotIdx]};
                    border-radius:8px;
                }
            `}
                onClick={handleImageClick}
            >
                <img className={classNames(robotIdx === 1 && 'active')} src="/images/robot1.png" alt="Aurora" />
                <img className={classNames(robotIdx === 2 && 'active')} src="/images/robot2.png" alt="Tempo" />
                <img className={classNames(robotIdx === 0 && 'active')} src="/images/robot0.png" alt="Penny" />
            </div>

            <div className='d-flex gap-3' style={{ flex: 1 }}>
                <div className="autocomplete-address-row" style={{ flex: 1 }}>
                    <div id="autocomplete-wrapper">
                        <AsyncTypeahead
                            filterBy={() => true}
                            id="autocomplete"
                            isLoading={loading}
                            labelKey="label"
                            minLength={1}
                            onSearch={handleSearch}
                            options={addressOptions}
                            placeholder={`Please Enter Your Address`}
                            size="lg"
                            onChange={
                                (val: any) => {
                                    setSelectedAddress(val)
                                }
                            }
                            selected={selectedAddress}
                        />
                    </div>
                    {chooseUtility()}
                </div>
                <Button
                    onClick={redirectTo}
                    disabled={!selectedAddress[0] || loadingUtility}
                    md
                    className={css`
                    margin-top: 20px;
                    width: 140px;
                `}
                >
                    <span>Start Now</span>
                </Button>
            </div>
        </div>
    )
}

export default ValidateAddressWidget
