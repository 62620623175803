import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faArrowDown,
    faBars,
    faCaretDown,
    faCaretUp,
    faCheckCircle,
    faCheckSquare,
    faCircle,
    faExchangeAlt,
    faFilePdf,
    faSearch,
    faSlidersH,
    faSquare,
    faTimes,
    faTimesCircle,
} from '@fortawesome/free-solid-svg-icons'
import 'react-datetime/css/react-datetime.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ThemeProvider } from 'styled-components'
import './App.css'
import { Enrollment } from './enrollment'
import { GetUsage } from './get_usage'
import { Homepage } from './homepage'
import './i18n'
import { AboutUs } from './pages/about-us'
import { ChooseRobot } from './pages/choose-robot'
import { ContactUs } from './pages/contact-us'
import { LetterOfAuthorization } from './pages/letter-of-authorization'
import PrivacyPolicy from './pages/privacy-policy'
import SuggestPlans from './pages/s_plans/suggest-plans'
import { SavingCalculation } from './pages/saving-calculation'
import { ShoppingTips } from './pages/shopping-tips'
import TOS from './pages/terms-of-service'
import ValidateAddressWidget from './pages/validate-address-widget'
import { Plans } from './plans'

library.add(
    faFilePdf as any,
    faTimesCircle as any,
    faSearch as any,
    faCheckCircle as any,
    faCheckSquare as any,
    faCircle as any,
    faSquare as any,
    faBars as any,
    faArrowDown as any,
    faTimes as any,
    faSlidersH as any,
    faCaretUp as any,
    faCaretDown as any,
    faExchangeAlt as any
)

const theme = {
    colors: {
        primary: '#00BDD6FF',
    },
}

function App() {
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Homepage />} />
                    <Route path="/plans/:robot" element={<Plans />} />
                    <Route path="/get_usage/:robot" element={<GetUsage />} />
                    <Route path="/enrollment" element={<Enrollment />} />
                    <Route path="/terms_of_service" element={<TOS />} />
                    <Route path="/privacy_policy" element={<PrivacyPolicy />} />
                    <Route path="/about_us" element={<AboutUs />} />
                    <Route path="/shopping_tips" element={<ShoppingTips />} />
                    <Route path="/saving_calculation" element={<SavingCalculation />} />
                    <Route path="/letter_of_authorization" element={<LetterOfAuthorization />} />

                    <Route path='/contact' element={<ContactUs />} />
                    <Route path="/choose_robot" element={<ChooseRobot />} />
                    <Route path='/suggest_plans/:robot' element={<SuggestPlans />} />
                    <Route path='/va_widget' element={<ValidateAddressWidget />} />
                </Routes>
            </BrowserRouter>
            <ToastContainer />
        </ThemeProvider>
    )
}

export default App
