import { css } from '@emotion/css';
import qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as MySVGImage } from './arrow_right.svg';
import { WCard } from './styles/MyComponents.styled';

const robotInfos = [{
  name: 'Penny',
  txt: `Penny is a friendly and approachable bot with a knack for saving money. She's always looking out for the best deals and is happy to help customers find the most affordable electricity and gas plans.`,
  intro: `Penny has a warm and inviting personality that puts customers at ease.`,
  color: '#00bdd6',
  triangle: 'up'
}, {
  name: 'Aurora',
  txt: `Aurora is a forward-thinking and eco-conscious bot. She's passionate about helping customers reduce their carbon footprint and find electricity and gas plans that are sustainable and affordable.`,
  intro: `Aurora is knowledgeable about green energy options and is always eager to share her expertise.`,
  color: '#17a948',
  triangle: 'down'
}, {
  name: 'Tempo',
  txt: `empo is a dynamic and efficient bot. She's adept at analyzing customer energy usage patterns and finding electricity and gas plans that fit their lifestyle.`,
  intro: `Tempo is focused on helping customers save time and money by maximizing their energy savings.`,
  color: '#ed7d2d',
  triangle: 'up'
}]

export const RobotInform = (props: any) => {

  let { idx = 0 } = props;

  const location = useLocation();
  const navigate = useNavigate();

  const { address: street, esiid, zipcode, city, state, utility_code: utcode } = qs.parse(
    location.search,
    { ignoreQueryPrefix: true }
  )

  const handleMore = () => {
    navigate(`/suggest_plans/${idx}?address=${encodeURIComponent(`${street}`)}&esiid=${esiid}&zipcode=${zipcode}&city=${city}&state=${state}&utility_code=${utcode}`)
  }

  return (
    <div style={{ zIndex: -1 }}>
      <WCard variant='up' color={robotInfos[idx].color} triangle={robotInfos[idx].triangle === 'up'}>
        <div className={css`
          display:flex;
          align-items:center;
          position:relative;
          margin:0 0 15px 10px;
        `}>
          <div className={css`
            font-size: 40px;
            font-weight: 700;
            color: #fff;
            margin-right: 10px;
          `}>{robotInfos[idx].name}</div>
          <WCard sm style={{
            position: 'absolute', width: '75%',
            right: -60, display: 'flex',
            justifyContent: 'space-between', alignItems: 'center',
            border: '1px solid #bcc1ca'
          }}>
            <div className={css`
              font-size: 14px;
              font-weight: 500;
            `}>
              <img width={30} src={`/images/circle_dollar${idx}.png`} alt="" style={{ marginRight: 12 }} />
              The best plan for your
            </div>
            <p
              onClick={handleMore}
              className={css`
              color: ${robotInfos[idx].color};
              cursor:pointer;
              font-size: 14px;
              text-decoration: none;
              margin-bottom: 0;
              flex:0 0 58px;
              :hover{
                color: ${robotInfos[idx].color};
              }
            `}>
              More <MySVGImage fill={robotInfos[idx].color} width={18} />
            </p>
          </WCard>
        </div>
        <div className={css`
          font-size: 12px;
          line-height: 1.7;
          color: #fff;
        `}>{robotInfos[idx].txt}</div>
      </WCard>
      <WCard variant='down' color='#f3f4f6' triangle={robotInfos[idx].triangle === 'down'}>
        <div className={css`
          display:flex;
          align-items:center;
          padding: 0 10px;
          gap:40px;
        `}>
          <div className={css`
            font-size: 12px;
            line-height: 1.7;
          `}>{robotInfos[idx].intro}</div>
          <img src="/images/telegram_logo.png" alt="" width={20} />
        </div>
      </WCard>
    </div>
  );
}