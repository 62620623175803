import styled from 'styled-components';

export const RobotBox = styled.div`
    width: 100%;
    height: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 100px 70px 100px 35px;
    display: flex;
    gap: 30px;
    
    @media (max-width: 768px){
        padding: 0 50px 0 20px;
    }
`

export const RobotCard = styled.div`
    flex: 1;
`

export const RobotImgBox = styled.div`
    height: 360px;
    position: relative;

    .robot{
        position: absolute;
        z-index:2;
    }

    .rb_bk{
        position: absolute;
        z-index:1;
    }

    .robot0{
        bottom: -57px;
        left: 30px;
    }

    .rb_bk0{
        right: 0;
        bottom: -12px;
    }

    .robot1{
        bottom: -5px;
        left: 60px;
    }

    .rb_bk1{
        right: -20px;
        bottom: -15px;
    }

    .robot2{
        bottom: -58px;
        right: -38px;
    }

    .rb_bk2{
        left: 15px;
        bottom: -40px;
    }
`