import { useLocalObservable } from 'mobx-react-lite'
import { Address } from '../../types'
import { createContext, useContext } from 'react'


const MyContext = createContext(null as any);

export const AppStoreProvider = ({ children }: { children: any }) => {
    const store = useLocalObservable(() => ({
        address: {} as Address,
        setAddress(address: Address) {
            store.address = address;
        },
        getAddress() {
            return store.address;
        },
    }));

    return (
        <MyContext.Provider value={store}>
            {children}
        </MyContext.Provider>
    );
};

export const useMyStore = () => {
    const store = useContext(MyContext);
    if (!store) {
        throw new Error("useMyStore must be used within an Provider");
    }
    return store;
};