import { css } from '@emotion/css'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import LoadingCircle from '../../components/loading-circle'

const LoadingWapper = styled.div`
    position:fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display:flex;
    flex-direction:column;
    justify-content:center;
    background: rgb(245, 245, 247);
    gap:20px;
    padding:0 32px;
    align-items:center;
    background:#e7ecef;
`

const LoadingPage = ({ setGrowNum }: { setGrowNum: Function }) => {
    const [planCounts, setPlanCounts] = useState<number>(0)

    useEffect(() => {
        let intervalId: NodeJS.Timeout;

        intervalId = setInterval(() => {
            const randomIncrease = Math.floor(Math.random() * 30) + 18;
            setPlanCounts((prevCounts) => prevCounts + randomIncrease);
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    useEffect(() => {
        setGrowNum(planCounts)
    }, [planCounts])

    useEffect(() => {
        if (planCounts > 600) {
            window.location.reload()
        }
    }, [planCounts])

    return (
        <LoadingWapper>
            <img src="/images/loading_bk.jpg" alt="" className={css`
                    position:absolute;
                    filter:blur(12px);                    
            `} />

            <LoadingCircle />

            <div className={css`
                font-size:30px;
                z-index:2;
                font-weight:500;

                @media (max-width:990px){
                    font-size:20px;
                }
                
            `} >Searching and analyzing {planCounts} plans</div>
        </LoadingWapper>
    )
}

export default LoadingPage
