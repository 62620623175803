import Header from '../components/header'
import { ContentPage } from '../components/styles/Page.styled'
import { Container } from 'react-bootstrap'

export const AboutUs = () => {
    return (
        <>
            <Header />
            <ContentPage>
                <Container>
                    <h1>About Us</h1>
                    <iframe
                        title='energyRus_widget'
                        width="100%"
                        height="180"
                        src="https://www.energyrus.com/va_widget"
                    ></iframe>
                </Container>
            </ContentPage>
        </>
    )
}
