import { css } from '@emotion/css';
import { t } from 'i18next';
import moment from 'moment';
import React, { useState } from 'react';
import { Button as BootstrapButton, Form } from 'react-bootstrap';
import Datetime from 'react-datetime';
import { toast } from 'react-toastify';
import { fetchElctricityRateLert } from '../api/api';
import { FormError } from './enrollment/FormError';
import { LoadingRow } from './loading-row';
import { MyButton } from './styles/MyComponents.styled';

const re = /\S+@\S+\.\S+/;

export const SubscribeModal: React.FC<any> = ({
    show,
    handleClose,
    address,
    zipcode,
    city,
    state,
}) => {
    let [loading, setLoading] = useState(false)
    const [inputValue, setInputValue] = useState<any>({
        address: address,
        zipcode: zipcode,
        city: city,
        state: state,
    })
    const [errorFields, setErrorFields] = useState<any>({})

    const addLert = () => {
        if (re.test(inputValue.email) === false) {
            setErrorFields({
                ...errorFields,
                [`email`]: t('Please enter your email')
            })
            return
        }
        if (inputValue.contract_date === undefined || inputValue.contract_date === '') {
            setErrorFields({
                ...errorFields,
                [`date`]: t('Please enter your contract expiration date')
            })
            return
        }

        setLoading(true)

        !loading && fetchElctricityRateLert(inputValue).then(res => {
            console.log(res)

            if (res.code === 200) {
                toast.success(res.message)
                handleClose()
            } else {
                toast.error(res.message)
            }

            setLoading(false)
        })
    }

    if (!show) return null

    return (
        <>
            <div className="side-modal-body" >
                <div className="position-absolute" style={{ top: 20, right: 20 }}>
                    <BootstrapButton
                        className="close-btn"
                        variant="link"
                        onClick={() => handleClose()}
                        style={{ fontSize: 32, color: '#000' }}>
                        <img src="/images/x.svg" alt="" width={24} />
                    </BootstrapButton>
                </div>

                <h2>Get notified when your contract is expiring</h2>
                <p>Have us notify you of the best recommended rate available right before your contract ends.</p>
                {loading && <LoadingRow />}

                <Form.Group>
                    <Form.Label>{t('Email')} *</Form.Label>
                    <Form.Control
                        type="email"
                        name="email"
                        placeholder=""
                        maxLength={150}
                        value={inputValue.email ? inputValue.email : ''}
                        onChange={(props => {
                            setInputValue({
                                ...inputValue,
                                [`email`]: props.target.value
                            })
                        })}
                        disabled={loading}
                    />
                </Form.Group>
                <FormError message={errorFields[`email`]} />

                <Form.Group>
                    <Form.Label>Current ontract expiration date</Form.Label>
                    <Datetime
                        value={inputValue.contract_date ? inputValue.contract_date.value : undefined}
                        onChange={(date: any) => {
                            setInputValue({
                                ...inputValue,
                                [`contract_date`]: moment(date).format('YYYY-MM-DD')
                            })
                        }}
                        initialViewDate={moment()}
                        dateFormat="MM/DD/YYYY"
                        timeFormat={false}
                        closeOnSelect={true}
                        isValidDate={(currentDate) => {
                            if (currentDate.isBefore(moment())) return false
                            return true
                        }}
                        inputProps={{
                            placeholder: t('MM/DD/YYYY'),
                            disabled: loading,
                        }}
                    />
                </Form.Group>
                <FormError message={errorFields[`date`]} />

                <MyButton
                    bkcolor='#00c1d9'
                    onClick={() => addLert()}
                    style={{ width: '100%', marginTop: '20px' }}
                >Submit</MyButton>
                <u
                    onClick={() => {
                        handleClose()
                    }}
                    className={css`
                        display:flex;
                        justify-content: center;
                        textdecoration: underline;
                        cursor:pointer;
                        margin-top:20px;
                `}>Not now</u>
            </div>
            <div className="mask" onClick={() => handleClose()} />
        </>
    )
}
