import { css } from '@emotion/css';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { TitleLine } from './styles/MyComponents.styled';

const HeaderTitleLine = styled(TitleLine)`
    font-size: 40px;
    font-weight: 600;
    text-align:start;
    font-style:italic;
    color:#fff;
    margin-bottom:20px;
    
    @media(max-width:990px){
        font-size:28px;
    }

    @media(max-width:768px){
        font-size:22px;
        margin-bottom:10px;
        color:#fff;
        padding:40px 0 30px;
        width:70%;
    }
`

const HeaderIntroLine = styled(TitleLine)`
    font-size: 20px;
    font-weight: 500;
    text-align:start;
    color:#fff;

    @media(max-width:768px){
        font-size:16px;
        text-align:center;
        color:#3c3c3c;
    }
`

const HomeHeader = () => {
    return (

        <Container>
            <div className={css`
                display:flex;
                flex-direction:column;
                justify-content:center;
                width:50%;
                padding:100px;
                padding-right:0;
                margin-bottom:50px;
                
                @media(max-width:1200px){
                    padding:50px;
                    width:60%;
                }

                @media(max-width:990px){
                    padding:30px;
                    width:70%;
                }

                @media(max-width:768px){
                    padding:0px;
                    width:100%;
                    margin-bottom:30px;
                }
            `} >
                <HeaderTitleLine>USE AI TO SAVE ON YOUR ELECTRICITY BILL</HeaderTitleLine>
                <HeaderIntroLine>Consolidated Texas Energy Marketplace to find the best electricity plan for your home</HeaderIntroLine>
            </div>

            <div>
                <TitleLine className='mb-3'>They are Your New Friends</TitleLine>
                <TitleLine style={{ fontSize: 20, fontWeight: 500 }}>Penny, Tempo, and Aurora are here to help you find the right electricity plan</TitleLine>
            </div>
        </Container>
    )
}

export default HomeHeader