import { css } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import qs from 'qs'
import React, { useEffect, useRef, useState } from 'react'
import { Button as BootstrapButton, Container, Dropdown, Pagination } from 'react-bootstrap'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
    fetchCommissionPlans,
    fetchDailyUsage,
    fetchEstUsage,
    fetchFilterOptions,
    fetchIntervalUsage,
    fetchMonthlyUsage,
    fetchPlans,
    fetchPremiseDetail
} from '../api/api'
import Footer from '../components/footer'
import { GetUsageComponent } from '../components/get_usage'
import Header from '../components/header'
import { LoadingRow } from '../components/loading-row'
import { CompareModal } from '../components/plan/CompareModal'
import { SidebySideModal } from '../components/sidebyside-modal'
import { Button } from '../components/styles/Button.styled'
import { CompareClearBtn, CompareToggleBar } from '../components/styles/Plan.styled'
import { AuroraBar, PennyBar, TempoBar } from '../pages/s_plans/robot-row'
import type {
    FilterValues,
    GetUsageModalProps,
    MonthlyUsageProps,
    PlanProps,
    PremiseInfo,
    SmtDailyReads,
    SmtIntervalReads,
    SmtMonthlyReads,
    Utilities
} from '../types'
import { ReactComponent as MySVGImage } from './arrow_right.svg'
import { PlanRow } from './plan-row'
import { PrepaidFilter } from './prepaid-filter'
import { ProviderFilter } from './provider-filter'
import { RenewableFilter } from './renewable-filter'
import { TermFilter } from './term-filter'
import { UtilitiesFilter } from './utilities-filter'

const color = ["#00bdd6", "#17a948", "#ed7d2d"]

export const Plans = () => {
    const { t } = useTranslation('plan')
    const sortOptions: Array<any> = [
        { label: t('Price: Low to high'), value: '1' },
        { label: t('Price: High to low'), value: '2' },
        { label: t('Plan Length: Low to high'), value: '3' },
        { label: t('Plan Length: High to low'), value: '4' },
    ]

    const location = useLocation()
    const idx = parseInt(location.pathname.split('/').pop() || '0');
    const navigate = useNavigate()
    const { address, esiid, zipcode, city, state, actualusage, usagefrom, utcode } = qs.parse(
        location.search,
        { ignoreQueryPrefix: true }
    )
    const [usage, setUsage] = useState<MonthlyUsageProps | undefined>(undefined)
    const [freeWeekendUsage, setFreeWeekendUsage] = useState(undefined)
    const [free7DaysUsage, setFree7DaysUsage] = useState(undefined)
    const [freeNightUsage, setFreeNightUsage] = useState(undefined)
    const [loading, setLoading] = useState(true)
    const [initializeFilter, setInitializeFilter] = useState(true)
    const [plans, setPlans] = useState<Array<PlanProps>>([])
    const [pagedPlans, setPagedPlans] = useState<Object | undefined>(undefined)
    const [filterValues, setFilterValues] = useState<FilterValues>({
        utility_id: '',
        term: [],
        provider_id: [],
        renewable: false,
        prepaid: 'all',
        type: 'normal'
    })
    const [reps, setReps] = useState([])
    const [terms, setTerms] = useState([])
    const [utilities, setUtilities] = useState<Array<Utilities>>([])
    const [availableTerms, setAvailableTerms] = useState<Array<string>>([])
    const [availableReps, setAvailableReps] = useState<Array<string>>([])
    const [showAccessSmtSection, setShowAccessSmtSection] = useState(false)
    const [consentId, setConsentId] = useState('')
    const [isEstMode, setIsEstMode] = useState(true)
    const [planType, setPlanType] = useState('normal')
    const [premiseDetail, setPremiseDetail] = useState<PremiseInfo>({} as PremiseInfo)
    const [selectedSortOption, setSelectedSortOption] = useState(sortOptions[0])
    const [currentPage, setCurrentPage] = useState(1)
    const [showFilters, setShowFilters] = useState(false)
    const [comparedPlan, setComparedPlan] = useState<PlanProps[]>([])
    const [showCompareModal, setShowCompareModal] = useState(false)
    const [planAvgPrices, setPlanAvgPrices] = useState({})
    const [cookies, setCookie, removeCookie] = useCookies(['consent_id'])
    const [showSidebySideModal, setShowSidebySideModal] = useState(false);
    const [usageLoading, setUsageLoading] = useState(false)

    const getUsageModalView = useRef('')
    const primaryFilter = useRef('')
    const allTerms = useRef(undefined)
    const allReps = useRef(undefined)
    const sidebysideComparedPlan = useRef<PlanProps | undefined>(undefined);

    const [totalUsage, setTotalUsage] = useState<number>(0)

    useEffect(() => {
        if (!address || !esiid || !zipcode || !city || !state || !utcode) {
            toast.error('Param is missing')
            return
        }

        if (cookies && cookies.consent_id) {
            setConsentId(cookies.consent_id)
            setIsEstMode(false)
        } else {
            setConsentId('')
            setIsEstMode(true)
        }

        getPremiseDetail()
        if (idx === 1) {
            handleShowGreen()
        }
        if (idx === 2) {
            handleSwitchPlanType('tou')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        actualusage ? setActualUsage(actualusage) : getEstUsage()
        showAccessSmtSection && setShowAccessSmtSection(false)
    }, [actualusage])

    const getEstUsage = () => {
        setLoading(true)
        setUsage(undefined)
        fetchEstUsage(address, city, state, zipcode).then((res) => {
            if (res && res.status === 1) {
                setUsage(res.usages)
            }
        })
    }

    const getPremiseDetail = () => {
        fetchPremiseDetail(zipcode, address).then((res) => {
            if (res.status === 1) {
                setPremiseDetail(res.premise)
            }
        })
    }

    const getMonthlyUsage = () => {
        setLoading(true)
        setUsage(undefined)
        const startDate = moment().subtract(1, 'years').format('MM/DD/YYYY')
        const endDate = moment().subtract(1, 'days').format('MM/DD/YYYY')
        fetchMonthlyUsage(esiid, consentId, startDate, endDate).then((res) => {
            if (res && res.response && res.response.reads) {
                const monthlyUsage = mergeMonthlyReads(res.response.reads)
                setUsage(monthlyUsage)
                setIsEstMode(false)
            }
        })
    }

    const getDailyUsage = () => {
        setLoading(true)
        setFreeWeekendUsage(undefined)
        setFree7DaysUsage(undefined)
        if (consentId) {
            const startDate = moment().subtract(1, 'years').format('MM/DD/YYYY')
            const endDate = moment().subtract(1, 'days').format('MM/DD/YYYY')
            fetchDailyUsage(esiid, consentId, startDate, endDate).then((res) => {
                if (res && res.response && res.response.reads) {
                    const free7DaysAndWeekendUsage = mergeDailyReads(res.response.reads)
                    setFree7DaysUsage(free7DaysAndWeekendUsage.free7Days)
                    setFreeWeekendUsage(free7DaysAndWeekendUsage.freeWeekend)
                }
            })
        } else {
            if (!usage) return
            let usages = Object.values(usage as any);
            setFree7DaysUsage(EstUsage(usages as any) as any)
            setFreeWeekendUsage(EstUsage(usages as any) as any)
        }
    }

    const getIntervalUsage = () => {
        setLoading(true)
        setFreeNightUsage(undefined)
        if (consentId) {
            const startDate = moment().subtract(1, 'years').format('MM/DD/YYYY')
            const endDate = moment().subtract(1, 'days').format('MM/DD/YYYY')
            fetchIntervalUsage(esiid, consentId, startDate, endDate).then((res) => {
                if (res && res.response && res.response.reads) {
                    const dayNightUsage = mergeIntervalReads(res.response.reads)
                    setFreeNightUsage(dayNightUsage)
                }
            })
        } else {
            if (!usage) return
            setFreeNightUsage(EstUsage(Object.values(usage as any)) as any)
        }
    }

    const EstUsage = (estUsage: Array<number>) => {
        const newObj: any = {}
        for (const [key, value] of Object.entries(estUsage)) {
            newObj[key] = { free: value * .3, charge: value * .7 }
        }
        return newObj
    }

    const mergeMonthlyReads = (data: Array<SmtMonthlyReads>) => {
        let usage: any = {}
        for (let i in data) {
            const key = moment(data[i].end_date, 'MM/DD/YYYY').format('M')
            usage[key] = usage[key]
                ? usage[key] + parseInt(data[i].actual_kwh)
                : parseInt(data[i].actual_kwh)
        }
        return usage
    }

    const mergeDailyReads = (data: Array<SmtDailyReads>) => {
        let freeWeekend: any = {},
            free7Days: any = {},
            dailyUsage: any = []

        for (let i in data) {
            const date = moment(data[i].read_date, 'MM/DD/YYYY')
            const m = date.format('M')
            const w = date.days()
            const objKey = w === 0 || w === 6 ? 'free' : 'charge'
            const usage = parseFloat(data[i].energy_data_kwh)

            if (!dailyUsage[m]) dailyUsage[m] = []
            if (!freeWeekend[m]) freeWeekend[m] = {}

            dailyUsage[m].push(usage)
            freeWeekend[m][objKey] = freeWeekend[m][objKey] ? freeWeekend[m][objKey] + usage : usage
        }

        for (let m in dailyUsage) {
            const oneMonth = dailyUsage[m]
            oneMonth.sort((a: string, b: string) => {
                if (parseInt(a) < parseInt(b)) return 1
                if (parseInt(a) > parseInt(b)) return -1
                return 0
            })
            oneMonth.forEach((val: number, idx: number) => {
                const objKey = idx < 7 ? 'free' : 'charge'
                if (!free7Days[m]) free7Days[m] = {}
                free7Days[m][objKey] = free7Days[m][objKey] ? free7Days[m][objKey] + val : val
            })
        }

        return { freeWeekend: freeWeekend, free7Days: free7Days }
    }

    const mergeIntervalReads = (data: Array<SmtIntervalReads>) => {
        let freeNight: any = {}
        for (let i in data) {
            const datetime = moment(data[i].datetime, 'MM/DD/YYYY HH:mm')
            const date = datetime.format('MM/DD/YYYY')
            const m = datetime.format('M')

            const objKey =
                datetime.isSameOrAfter(`${date} 20:00`, 'minute') ||
                    datetime.isBefore(`${date} 05:59`, 'minute')
                    ? 'free'
                    : 'charge'
            if (!freeNight[m]) freeNight[m] = {}
            freeNight[m][objKey] = freeNight[m][objKey]
                ? freeNight[m][objKey] + parseFloat(data[i].usage)
                : parseFloat(data[i].usage)
        }

        return freeNight
    }

    useEffect(() => {
        if (!usage) {
            return
        }
        let total = 0;

        for (let key in usage) {
            let item = parseInt(usage[key]);
            total += item;
        }
        setTotalUsage(total)
    }, [usage])

    useEffect(() => {
        if (!usage) return
        if (planType === 'normal') {
            getNormalPlans()
            getFilterOptions()
        }
        if (planType === 'tou') {
            if (!freeWeekendUsage || !free7DaysUsage || !freeNightUsage) {
                if (!usageLoading) {
                    setUsageLoading(true)
                    getDailyUsage()
                    getIntervalUsage()
                }
                return
            }
            getTouPlans()
            getFilterOptions()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usage, freeWeekendUsage, free7DaysUsage, freeNightUsage, filterValues])

    const sortPlan = (data: Array<PlanProps>) => {
        let [...newData] = data
        // console.time('sorting');
        newData.sort((a: PlanProps, b: PlanProps) => {
            switch (selectedSortOption.value) {
                case '1':
                    if (a.rate > b.rate) return 1
                    if (a.rate < b.rate) return -1
                    return 0
                case '2':
                    if (a.rate < b.rate) return 1
                    if (a.rate > b.rate) return -1
                    return 0
                case '3':
                    if (parseInt(a.contract_term) > parseInt(b.contract_term)) return 1
                    if (parseInt(a.contract_term) < parseInt(b.contract_term)) return -1
                    return 0
                case '4':
                    if (parseInt(a.contract_term) < parseInt(b.contract_term)) return 1
                    if (parseInt(a.contract_term) > parseInt(b.contract_term)) return -1
                    return 0
                default:
                    return 0
            }
        })
        // console.timeEnd('sorting');

        return paginatePlan(newData)
    }

    const paginatePlan = (data: Array<PlanProps>) => {
        if (data.length === 0) {
            setPagedPlans(undefined)
            setCurrentPage(1)
            return
        }
        const per = 50
        let pagedData: any = {}

        // console.time('paginating');
        let p = 0
        data.forEach((val, idx) => {
            if (idx % per === 0) p += 1
            if (!pagedData[p]) pagedData[p] = []
            pagedData[p].push(val)
        })
        // console.timeEnd('paginating');

        setPagedPlans(pagedData)
    }

    const getNormalPlans = () => {
        setLoading(true)
        setPlans([])
        setPagedPlans(undefined)
        setCurrentPage(1)
        fetchCommissionPlans(planType, usage, zipcode, filterValues, `${utcode}`).then((res) => {
            setLoading(false)
            if (res && res.response && res.response.plans) {
                sortPlan(res.response.plans)
                setPlans(res.response.plans)
            } else {
                if (res.error_code === '500') {
                    setPagedPlans(undefined)
                    setCurrentPage(1)
                    setPlans([])
                }
            }
        })
    }

    const getTouPlans = async () => {
        setLoading(true)
        setPlans([])
        setPagedPlans(undefined)
        setCurrentPage(1)
        let plans: Array<PlanProps> = []

        await fetchPlans('night', freeNightUsage, zipcode, filterValues, `${utcode}`).then(
            (res) => {
                if (res && res.response && res.response.plans) {
                    plans = plans.concat(res.response.plans)
                }
            }
        )
        await fetchPlans('weekend', freeWeekendUsage, zipcode, filterValues, `${utcode}`).then(
            (res) => {
                if (res && res.response && res.response.plans) {
                    plans = plans.concat(res.response.plans)
                }
            }
        )
        await fetchPlans('7days', free7DaysUsage, zipcode, filterValues, `${utcode}`).then(
            (res) => {
                if (res && res.response && res.response.plans) {
                    plans = plans.concat(res.response.plans)
                }
            }
        )
        setLoading(false)
        setPlans(plans)
        sortPlan(plans)
    }

    const getFilterOptions = () => {
        fetchFilterOptions(planType, usage, zipcode, filterValues, `${utcode}`).then((res) => {
            if (res && res.response) {
                let { reps, terms, utilities } = res.response
                let newAvailableTerms = [],
                    newAvailableReps = []
                if (primaryFilter.current === '') {
                    reps.sort((a: any, b: any) => {
                        if (a.name < b.name) return -1
                        if (a.name > b.name) return 1
                        return 0
                    })
                    allReps.current = reps
                    setReps(reps)
                    terms.sort((a: string, b: string) => {
                        if (parseInt(a) < parseInt(b)) return -1
                        if (parseInt(a) > parseInt(b)) return 1
                        return 0
                    })
                    allTerms.current = terms
                    setTerms(terms)
                    setUtilities(utilities)
                    setInitializeFilter(false)
                }

                if (
                    primaryFilter.current === '' ||
                    primaryFilter.current === 'term' ||
                    filterValues.term.length === 0
                ) {
                    const _reps = filterValues.term.length === 0 ? allReps.current : reps
                    for (let i in _reps) {
                        newAvailableReps.push(_reps[i].provider_id)
                    }
                    setAvailableReps(newAvailableReps)
                }

                if (
                    primaryFilter.current === '' ||
                    primaryFilter.current === 'provider' ||
                    filterValues.provider_id.length === 0
                ) {
                    const _terms = filterValues.provider_id.length === 0 ? allTerms.current : terms
                    for (let i in _terms) {
                        newAvailableTerms.push(_terms[i])
                    }
                    setAvailableTerms(newAvailableTerms)
                }
            } else {
                if (res.error_code === '500') {
                    setAvailableTerms([])
                    setAvailableReps([])
                    setInitializeFilter(false)
                }
            }
        })
    }

    const setActualUsage = (usage: any) => {
        const actualUsageParams = usage.toString().split('-')
        let actualUsage: MonthlyUsageProps = {}
        for (let i in actualUsageParams) {
            actualUsage[parseInt(i) + 1] = actualUsageParams[i]
        }
        setUsage(actualUsage)
        setIsEstMode(false)
    }

    const handleSwitchPlanType = (type: string) => {
        setPlanType(type)
        let newFilterValues = {
            utility_id: '',
            term: [],
            provider_id: [],
            renewable: false,
            prepaid: 'all',
            type: 'normal'
        }
        newFilterValues.type = type
        setFilterValues(newFilterValues)

        primaryFilter.current = ''
        setInitializeFilter(true)

        setUsage(undefined)
        setFree7DaysUsage(undefined)
        setFreeNightUsage(undefined)
        setFreeWeekendUsage(undefined)
        if (type === 'normal') {
            /* These actual usage data are getting from TDU */
            if (actualusage && actualusage.toString().split('-').length === 12) {
                setActualUsage(actualusage)
            } else {
                isEstMode ? getEstUsage() : getMonthlyUsage()
            }
        } else {
            getDailyUsage()
            getIntervalUsage()
        }
    }

    const handleBack = () => {
        navigate(`/suggest_plans/${idx}?address=${encodeURIComponent(`${address}`)}&esiid=${esiid}&zipcode=${zipcode}&city=${city}&state=${state}&utcode=${utcode}`)
    }

    const handleShowGreen = () => {
        let newFilterValues = Object.assign({}, filterValues)
        newFilterValues.renewable = !newFilterValues.renewable
        setFilterValues(newFilterValues)
    }

    useEffect(() => {
        if (loading || !plans) return
        sortPlan(plans)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSortOption])

    const goToEnroll = (planId: string) => {
        navigate(
            `/enrollment?plan_id=${planId}&address=${encodeURIComponent(
                `${address}`
            )}&esiid=${esiid}&zipcode=${zipcode}&city=${city}&state=${state}&utility_code=${utcode}&step=service_date`
        )
    }

    return (
        <>
            <Header />

            <Container >
                <div className={css`
                    display: flex;
                    justify-content: space-between;
                    margin:20px 0 10px;
                    @media (max-width: 768px) {
                        flex-direction: column-reverse;
                    }
                `}>
                    <div />

                    <div style={{
                        textDecoration: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 5,
                        justifyContent: 'flex-end',
                        color: color[idx]
                    }} onClick={handleBack}>
                        <div style={{ cursor: 'pointer' }}>
                            <MySVGImage fill={color[idx]} width={18} height={18} style={{
                                transform: 'rotateY(180deg)',
                            }} /> Back
                        </div>
                    </div>
                </div>
            </Container>

            <Container>
                {idx === 0 && <PennyBar usage={usage} premiseDetail={premiseDetail} totalUsage={totalUsage} />}
                {idx === 1 && <AuroraBar premiseDetail={premiseDetail} totalUsage={totalUsage} />}
                {idx === 2 && <TempoBar usage={usage} premiseDetail={premiseDetail} totalUsage={totalUsage} />}
            </Container>

            <Container>
                <div id="plans-wrapper">
                    <div id="plans-aside">
                        <div id="plans-filter-group" className={showFilters ? 'show' : ''}>
                            <div className="plans-filter">
                                <div className="plans-filter-title">
                                    <span>
                                        {t('Renewable')}
                                        <img src="/images/icon_leaft.png" alt="" width={20} className='ms-2' />
                                    </span>
                                </div>
                                <div className="plans-filter-body">
                                    <RenewableFilter
                                        initializeFilter={initializeFilter}
                                        loading={loading}
                                        value={filterValues.renewable}
                                        handleClick={handleShowGreen}
                                    />
                                </div>
                            </div>
                            <div className="plans-filter">
                                <div className="plans-filter-title">{t('Prepaid')}</div>
                                <div className="plans-filter-body">
                                    <PrepaidFilter
                                        initializeFilter={initializeFilter}
                                        loading={loading}
                                        value={filterValues.prepaid}
                                        handleClick={(val: any) => {
                                            let newFilterValues = Object.assign({}, filterValues)
                                            newFilterValues.prepaid = val
                                            setFilterValues(newFilterValues)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="plans-filter">
                                <div className="plans-filter-title">{t('Provider')}</div>
                                <div className="plans-filter-body">
                                    <ProviderFilter
                                        initializeFilter={initializeFilter}
                                        value={filterValues.provider_id}
                                        loading={loading}
                                        handleClick={(val: any) => {
                                            let newFilterValues = Object.assign({}, filterValues)
                                            if (val) {
                                                const idx = newFilterValues.provider_id.indexOf(val)
                                                if (idx >= 0) {
                                                    newFilterValues.provider_id.splice(idx, 1)
                                                } else {
                                                    newFilterValues.provider_id.push(val)
                                                }
                                            } else {
                                                newFilterValues.provider_id = []
                                            }
                                            if (newFilterValues.provider_id.length > 0) {
                                                if (primaryFilter.current === '') {
                                                    primaryFilter.current = 'provider'
                                                }
                                            } else {
                                                if (primaryFilter.current === 'provider') {
                                                    if (newFilterValues.term.length > 0) {
                                                        primaryFilter.current = 'term'
                                                    } else {
                                                        primaryFilter.current = ''
                                                    }
                                                }
                                            }
                                            setFilterValues(newFilterValues)
                                        }}
                                        availableReps={availableReps}
                                        reps={reps}
                                    />
                                </div>
                            </div>
                            {utilities.length > 1 && (
                                <div className="plans-filter">
                                    <div className="plans-filter-title">{t('Utilities')}</div>
                                    <div className="plans-filter-body">
                                        <UtilitiesFilter
                                            initializeFilter={initializeFilter}
                                            loading={loading}
                                            utilities={utilities}
                                            value={filterValues.utility_id}
                                            handleClick={(val: string) => {
                                                let newFilterValues = Object.assign(
                                                    {},
                                                    filterValues
                                                )
                                                newFilterValues.utility_id = val
                                                setFilterValues(newFilterValues)
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="position-absolute" style={{ top: 20, right: 20 }}>
                                <BootstrapButton
                                    className="close-btn"
                                    variant="link"
                                    onClick={() => {
                                        setShowFilters(false)
                                    }}
                                    style={{ fontSize: 32, color: '#000' }}>
                                    <FontAwesomeIcon icon="times" />
                                </BootstrapButton>
                            </div>
                        </div>
                    </div>
                    <div id="plans-list">
                        <div
                            className={css`
                                max-width: 980px;
                                margin: 0 auto;
                            `}>
                            <div
                                id="plans-list-header"
                                className="d-flex align-items-center justify-content-between">
                                <div
                                    className={css`
                                        font-size: 24px;
                                        font-weight: 500;
                                        @media (max-width: 750px) {
                                            margin-bottom: 12px;
                                            align-self: flex-start;
                                        }
                                    `}>
                                    {t('All Plans')}
                                    {plans.length > 0 && (
                                        <span style={{ fontSize: 14, marginLeft: 3 }}>
                                            ({plans.length})
                                        </span>
                                    )}
                                </div>
                                <div
                                    className={`d-flex align-items-center justify-content-center ${css`
                                        @media (max-width: 750px) {
                                            width: 100%;
                                            flex-direction: column;
                                        }
                                    `}`}>
                                    <div id="term-filter-wrapper">
                                        <TermFilter
                                            initializeFilter={initializeFilter}
                                            availableTerms={availableTerms}
                                            loading={loading}
                                            handleClick={(val: any) => {
                                                let newFilterValues = Object.assign(
                                                    {},
                                                    filterValues
                                                )
                                                if (val !== '0') {
                                                    newFilterValues.term = val.split(',')
                                                } else {
                                                    newFilterValues.term = []
                                                }
                                                if (newFilterValues.term.length > 0) {
                                                    if (primaryFilter.current === '') {
                                                        primaryFilter.current = 'term'
                                                    }
                                                } else {
                                                    if (primaryFilter.current === 'term') {
                                                        if (
                                                            newFilterValues.provider_id.length > 0
                                                        ) {
                                                            primaryFilter.current = 'provider'
                                                        } else {
                                                            primaryFilter.current = ''
                                                        }
                                                    }
                                                }
                                                setFilterValues(newFilterValues)
                                            }}
                                            terms={terms}
                                        />
                                    </div>
                                    <div
                                        className={`d-flex align-items-center ${css`
                                            @media (max-width: 750px) {
                                                width: 100%;
                                            }
                                        `}`}>
                                        <BootstrapButton
                                            variant="light"
                                            id="plans-filter-toggle"
                                            onClick={() => {
                                                setShowFilters(!showFilters)
                                            }}
                                            className="me-3">
                                            <FontAwesomeIcon icon="sliders-h" className="me-2" />
                                            {t('Plan Filters')}
                                        </BootstrapButton>
                                        <Dropdown
                                            id="plans-sort-toggle"
                                            className="plans-filter-dd">
                                            <Dropdown.Toggle
                                                variant="light"
                                                disabled={loading || plans.length === 0}>
                                                {((val) => {
                                                    const res = sortOptions.filter(
                                                        (item) => item.value === val
                                                    )
                                                    return res[0].label
                                                })(selectedSortOption.value)}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {sortOptions.map((val, idx) => (
                                                    <Dropdown.Item
                                                        eventKey={val}
                                                        key={idx}
                                                        onClick={() => {
                                                            setSelectedSortOption(val)
                                                        }}
                                                        active={
                                                            selectedSortOption.value === val.value
                                                        }>
                                                        {val.label}
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                            {loading && (
                                <div className="pt-5 pb-5">
                                    <LoadingRow />
                                </div>
                            )}
                            {!loading && !pagedPlans && <NoDataRow />}
                            {!loading && pagedPlans && (
                                <div>
                                    {planType === 'tou' && consentId === '' && <NoRateTipsRow />}
                                    {(pagedPlans as any)[currentPage].map(
                                        (val: PlanProps, idx: string) => (
                                            <PlanRow
                                                key={val.plan_id}
                                                val={val}
                                                usage={usage}
                                                avgPrice={
                                                    (planAvgPrices as any)[`${val.contract_term}`]
                                                        ? (planAvgPrices as any)[
                                                        `${val.contract_term}`
                                                        ]
                                                        : '0'
                                                }
                                                hidePrice={planType === 'tou' && consentId === ''}
                                                handleShowAccessSmtSection={() => {
                                                    getUsageModalView.current = 'smt'
                                                    setShowAccessSmtSection(true)
                                                }}
                                                handleCompare={() => {
                                                    let [...newComparedPlan] = comparedPlan
                                                    let idx = -1
                                                    for (let i in newComparedPlan) {
                                                        if (
                                                            newComparedPlan[i].plan_id ===
                                                            val.plan_id
                                                        ) {
                                                            idx = parseInt(i)
                                                            break
                                                        }
                                                    }
                                                    if (idx >= 0) {
                                                        newComparedPlan.splice(idx, 1)
                                                    } else {
                                                        if (newComparedPlan.length >= 3) {
                                                            toast.warning(
                                                                `${t(
                                                                    'You can compare upto 3 plans'
                                                                )}`
                                                            )
                                                            return
                                                        }
                                                        let _plan = Object.assign({}, val)
                                                        _plan.is_tou_plan = planType === 'tou'
                                                        _plan.hide_price =
                                                            planType === 'tou' && consentId === ''
                                                        newComparedPlan.push(_plan)
                                                    }
                                                    setComparedPlan(newComparedPlan)
                                                }}
                                                compare={
                                                    comparedPlan.filter(
                                                        (item) => item.plan_id === val.plan_id
                                                    ).length > 0
                                                }
                                                handleClickEnroll={(planId: string) =>
                                                    goToEnroll(planId)
                                                }
                                                handleShowSidebySideModal={(val: PlanProps) => {
                                                    sidebysideComparedPlan.current = val;
                                                    setShowSidebySideModal(true)
                                                }}
                                            />
                                        )
                                    )}
                                    {Object.keys(pagedPlans).length > 1 && (
                                        <div className="mt-5 d-flex justify-content-center">
                                            <Pagination>
                                                {Object.keys(pagedPlans).map((key: string) => (
                                                    <Pagination.Item
                                                        key={key}
                                                        active={parseInt(key) === currentPage}
                                                        onClick={(evt) => {
                                                            setCurrentPage(
                                                                parseInt(
                                                                    (evt.target as any).innerText
                                                                )
                                                            )
                                                            window.scrollTo(0, 0)
                                                        }}>
                                                        {key}
                                                    </Pagination.Item>
                                                ))}
                                            </Pagination>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <GetUsageModal
                    show={showAccessSmtSection}
                    handleClose={() => {
                        setShowAccessSmtSection(false)
                        getUsageModalView.current = ''
                    }}
                    handleSetConsentId={(id: string) => {
                        setConsentId(id)
                        setCookie('consent_id', id, { path: '/', maxAge: 3600 })
                    }}
                    handleFetchActualUsage={() => getMonthlyUsage()}
                    defaultView={getUsageModalView.current}
                    zipcode={`${zipcode}`}
                />
                <CompareToggle
                    number={comparedPlan.length}
                    handleCompare={() => {
                        setShowCompareModal(true)
                    }}
                    handleClear={() => setComparedPlan([])}
                />
                <CompareModal
                    show={showCompareModal}
                    handleCloseModal={() => {
                        setShowCompareModal(false)
                    }}
                    comparePlans={comparedPlan}
                    handleClickEnroll={(planId: string) => goToEnroll(planId)}
                />
                <SidebySideModal
                    show={showSidebySideModal}
                    thatPlan={sidebysideComparedPlan.current}
                    handleClose={() => {
                        sidebysideComparedPlan.current = undefined
                        setShowSidebySideModal(false)
                    }}
                    zipcode={`${zipcode}`}
                    utilityCode={`${utcode}`}
                    usages={usage}
                    planType={planType}
                    handleClickEnroll={(planId: string) =>
                        goToEnroll(planId)
                    }
                    terms={filterValues.term}
                />
            </Container>

            <Footer />
        </>
    )
}

const CompareToggle = ({
    number,
    handleCompare,
    handleClear,
}: {
    number: number
    handleCompare: Function
    handleClear: Function
}) => {
    const { t } = useTranslation('plan')
    return (
        <CompareToggleBar show={number > 0}>
            <Button md disabled={number < 2} onClick={() => handleCompare()}>
                {t('Compare')} {number}/3
            </Button>
            <CompareClearBtn onClick={() => handleClear()}>{t('Clear All')}</CompareClearBtn>
        </CompareToggleBar>
    )
}

const GetUsageModal: React.FC<GetUsageModalProps> = ({
    show,
    handleClose,
    handleSetConsentId,
    handleFetchActualUsage,
    defaultView,
    zipcode,
}) => {
    if (!show) return null
    return (
        <div className={`${show ? 'active' : ''}`}>
            <div className="side-modal-body">
                <div className="position-absolute" style={{ top: 20, right: 20 }}>
                    <BootstrapButton
                        className="close-btn"
                        variant="link"
                        onClick={() => handleClose()}
                        style={{ fontSize: 32, color: '#000' }}>
                        <FontAwesomeIcon icon="times" />
                    </BootstrapButton>
                </div>
                <GetUsageComponent
                    fetchActualUsage={() => {
                        handleFetchActualUsage()
                        handleClose()
                    }}
                    handleSetConsentId={handleSetConsentId}
                    defaultView={defaultView}
                    inModal={true}
                    zipcode={zipcode}
                    forcastUsage={() => {
                        handleClose()
                    }}
                />
            </div>
            <div className="mask" onClick={() => handleClose()} />
        </div>
    )
}

const NoRateTipsRow = () => {
    const { t } = useTranslation('plan')
    return (
        <div
            className={css`
                display: flex;
                background-color: #fef6f0;
                font-size: 14px;
                color: #666;
                line-height: 1.5;
                margin: 14px 0;
                border-radius: 4px;
                padding: 20px 24px;
                align-items: center;
                @media (max-width: 640px) {
                    flex-direction: column;
                    margin-left: -4px;
                    margin-right: -4px;
                    align-items: flex-start;
                }
            `}>
            <span
                className={css`
                    flex-shrink: 0;
                    color: #ed7d2d;
                    font-size: 16px;
                    display: inline-block;
                    font-weight: 500;
                    margin: 0 16px 0 0;
                `}>
                {t('No rate showing?')}
            </span>
            <span>
                {t(
                    'To calculate your personalized rate, we need to access your smart meter (SMT) data to determine your time of use. Click [SMT Consent] to enable calculation and find the best rate for you.'
                )}
            </span>
        </div>
    )
}

const NoDataRow = () => {
    const { t } = useTranslation('plan')
    return (
        <div
            className={css`
                padding-top: 44px;
                padding-bottom: 44px;
                text-align: center;
                color: #666;
            `}>
            {t('No Data Found')}
        </div>
    )
}
