import { css } from '@emotion/css'
import React from 'react'

const LoadingCircle = () => {
    return (
        <div className={css`
            width:100%;
            display:flex;
            justify-content:center;
            p{
                margin:0;
                font-size:13px;
            }

            .loading,
            .loading > div {
                position: relative;
                box-sizing: border-box;
            }

            .loading {
                display: block;
                font-size: 0;
                color: #000;
            }

            .loading.la-dark {
                color: #333;
            }

            .loading > div {
                display: inline-block;
                float: none;
                background-color: currentColor;
                border: 0 solid currentColor;
            }

            .loading {
                width: 96px;
                height: 96px;
                display:flex;
                align-items:center;
                justify-content:center;
            }

            .loading > div {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 10px;
                height: 10px;
                margin-top: -4px;
                margin-left: -4px;
                border-radius: 100%;
                animation: ball-spin-clockwise-fade 1s infinite linear;
            }

            .loading > div:nth-child(1) {
                top: 5%;
                left: 50%;
                animation-delay: -0.875s;
                color:#0154a4;
            }

            .loading > div:nth-child(2) {
                top: 18.1801948466%;
                left: 81.8198051534%;
                animation-delay: -0.75s;
                color:#6a429a;
            }

            .loading > div:nth-child(3) {
                top: 50%;
                left: 95%;
                animation-delay: -0.625s;
                color:#a54398;
            }

            .loading > div:nth-child(4) {
                top: 81.8198051534%;
                left: 81.8198051534%;
                animation-delay: -0.5s;
                color:#e33f96;
            }

            .loading > div:nth-child(5) {
                top: 94.9999999966%;
                left: 50.0000000005%;
                animation-delay: -0.375s;
                color:#eb1a23;
            }

            .loading > div:nth-child(6) {
                top: 81.8198046966%;
                left: 18.1801949248%;
                animation-delay: -0.25s;
                color:#f26f1f;
            }

            .loading > div:nth-child(7) {
                top: 49.9999750815%;
                left: 5.0000051215%;
                animation-delay: -0.125s;
                color:#f58e1d;
            }

            .loading > div:nth-child(8) {
                top: 18.179464974%;
                left: 18.1803700518%;
                animation-delay: 0s;
                color:#ffc20f;
            }

            .loading.la-sm {
                width: 16px;
                height: 16px;
            }

            .loading.la-sm > div {
                width: 4px;
                height: 4px;
                margin-top: -2px;
                margin-left: -2px;
            }

            .loading.la-2x {
                width: 64px;
                height: 64px;
            }

            .loading.la-2x > div {
                width: 16px;
                height: 16px;
                margin-top: -8px;
                margin-left: -8px;
            }

            .loading.la-3x {
                width: 96px;
                height: 96px;
            }

            .loading.la-3x > div {
                width: 24px;
                height: 24px;
                margin-top: -12px;
                margin-left: -12px;
            }

            @keyframes ball-spin-clockwise-fade {
            50% {
                opacity: 0.25;
                transform: scale(0.5);
            }

            100% {
                opacity: 1;
                transform: scale(1);
            }
        }`}>
            <div className='loading'>
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <p>LOADING...</p>
            </div>
        </div>
    )
}

export default LoadingCircle
