import { css } from '@emotion/css';
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router';


const Header = () => {
    const { t } = useTranslation();
    const nav = [
        { label: t('Shopping Tips'), url: "#" }
    ]

    let location = useLocation()
    const idx = parseInt(location.pathname.split('/').pop() || '0');
    let navigate = useNavigate()
    const [showNav, setShowNav] = useState(false);
    let [showChooseBtn, setShowChooseBtn] = useState(false);

    useEffect(() => {
        location.pathname.split('/')[1] === 'suggest_plans' || location.pathname.split('/')[1] === 'plans' ? setShowChooseBtn(true) : setShowChooseBtn(false);
    }, [])

    const handleClickSwitch = (index: number) => {
        navigate(`/${location.pathname.split('/')[1]}/${index}${location.search}`, { replace: true })
        window.location.reload()
    }

    return (
        <div className={css`
            height: 64px;
            border-bottom:2px #eee solid;
        `}>
            <div className={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 64px;
                    max-width: 1440px;
                    margin: 0 auto;
                    padding: 0 70px;

                    a {
                        text-decoration: none;
                    }

                    @media (max-width:768px){
                        justify-content: center;
                    }
                `}>
                <a href="/" className={css`
                        font-size: 20px;
                        font-weight: 700;
                        color: #000;
                    `}><img src='/images/logo-wide.png' alt='' height={35} /></a>
            </div>
        </div >
    )
}

export default Header