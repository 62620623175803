import { css } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Slider, ThemeProvider, createTheme } from '@mui/material'
import { t } from 'i18next'
import moment from 'moment'
import qs from 'qs'
import { useEffect, useState } from 'react'
import { Button as BootstrapButton, Col, Modal, Row } from 'react-bootstrap'
import { useCookies } from 'react-cookie'
import { useLocation } from 'react-router-dom'
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { fetchMonthlyUsage, getGreenEnergy, getSaveCarbon } from '../../api/api'
import { useUpdateEffect } from '../../common/hooks/myHooks'
import { GetUsageComponent } from '../../components/get_usage'
import { SearchAddressInner } from '../../components/styles/Homepage.styled'
import { Card, MyButton, RobotBtn } from '../../components/styles/MyComponents.styled'
import { GetUsageModalProps, PremiseInfo, RenewableProps, SaveCarbonProps, SmtMonthlyReads } from '../../types'
import { ValidateAddress } from '../../validate_address'

const outerTheme = createTheme({
    palette: {
        primary: {
            main: "#f19b5d",
        },
    },
});

const MyCol = (props: any) => {
    return <Col
        {...props.filter ?
            props.filter((item: any) => item !== 'children') :
            props
        }

        className={css`
        padding:10px
    `}>
        {props.children}
    </Col>
}

function SemiCircleChart(props: { value: number }) {
    let { value } = props
    const angle = (value / 100) * 180 || 108;
    const style: any = { '--angle': angle + 'deg' };

    return (
        <div className="sc-gauge">
            <div className="sc-background">
                <div className="sc-percentage" style={style} />
                <div className="sc-mask" />
                <span className="sc-value">{value ? (value).toFixed(1) : "??.?"}%</span>
            </div>
        </div>
    )
}

const AuroraBar = ({ premiseDetail, totalUsage }: { premiseDetail: PremiseInfo, totalUsage: number }) => {
    const location = useLocation()

    const [consentId, setConsentId] = useState<string>()
    const [cookies, setCookie, removeCookie] = useCookies(['consent_id'])
    const [showAccessSmtSection, setShowAccessSmtSection] = useState(false)
    const [monthlyUsage, setMonthlyUsage] = useState<any>(null)
    const [renewable, setRenewable] = useState<RenewableProps>({} as RenewableProps)
    const [saveCarbon, setSaveCarbon] = useState<SaveCarbonProps>()

    const { actualusage, zipcode, esiid } = qs.parse(
        location.search,
        { ignoreQueryPrefix: true }
    )

    useEffect(() => {
        if (cookies && cookies.consent_id) {
            setConsentId(cookies.consent_id)
        }
    }, [monthlyUsage])

    useEffect(() => {
        if (actualusage && showAccessSmtSection) {
            setShowAccessSmtSection(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actualusage])

    const getMonthlyUsage = () => {
        const startDate = moment().subtract(1, 'years').format('MM/DD/YYYY')
        const endDate = moment().subtract(1, 'days').format('MM/DD/YYYY')
        fetchMonthlyUsage(esiid, consentId, startDate, endDate).then((res) => {
            if (res && res.response && res.response.reads) {
                const monthlyUsage = mergeMonthlyReads(res.response.reads)
                setMonthlyUsage(monthlyUsage)
            }
        })
    }

    useEffect(() => {
        getGreenEnergy(zipcode, consentId).then((res) => {
            if (res && res.response) {
                setRenewable(res.response)
            }
        })
    }, [consentId])

    useEffect(() => {
        if (totalUsage < 1) {
            return
        }
        getSaveCarbon(zipcode, totalUsage).then((res) => {
            if (res && res.data) {
                setSaveCarbon(res.data)
            }
        })
    }, [totalUsage])

    const mergeMonthlyReads = (data: Array<SmtMonthlyReads>) => {
        let usage: any = {}
        for (let i in data) {
            const key = moment(data[i].end_date, 'MM/DD/YYYY').format('M')
            usage[key] = usage[key]
                ? usage[key] + parseInt(data[i].actual_kwh)
                : parseInt(data[i].actual_kwh)
        }
        return usage
    }

    return (
        <>
            <Row>
                <MyCol md={6} lg={3} >
                    <Card color='#f0fdf3'>
                        <div className='d-flex flex-column justify-content-around' style={{ height: '100%' }}>
                            <div className='d-flex align-items-center'>
                                <img src="/images/icon_user_green.png" alt="" width={42} height={42} />
                                <div className='d-flex flex-column flex-grow-1 ms-4' >
                                    <div style={{ fontSize: 14 }}>Annual Usage</div>
                                    <div style={{
                                        fontSize: 20,
                                        fontWeight: 600, color: '#0a4d20'
                                    }}>{(totalUsage).toLocaleString()}kwh</div>
                                </div>
                            </div>
                            <div className='mt-3 d-flex align-items-center'>
                                <img src="/images/icon_leaft.png" alt="" width={42} height={42} />
                                <div className='d-flex flex-column flex-grow-1 ms-4' >
                                    <div style={{ fontSize: 14 }}>Green  Energy</div>
                                    <div style={{
                                        fontSize: 20,
                                        fontWeight: 600, color: '#1dd75b'
                                    }}>{(totalUsage * renewable.renewable_content / 100).toLocaleString()}kwh</div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </MyCol>
                <MyCol md={6} lg={3} >
                    <AnalysisResultsCard premiseDetail={premiseDetail} idx={1} />
                </MyCol>
                <MyCol md={12} lg={6}>
                    <Card color='#eefdf3' className={css`
                        display: flex;
                        gap: 20px;

                        @media (max-width: 768px) {
                            flex-direction: column;
                        }
                    `}>
                        <div className='d-flex flex-column align-items-center'>
                            <div className={css`
                                        font-size: 14px;  
                                        font-weight: 600;
                                        margin-bottom: 10px;
                                        white-space: nowrap;
                                    `}>Proportion Of Green Energy</div>
                            <SemiCircleChart value={renewable.renewable_content} />
                        </div>

                        <div className={css`
                            @media (max-width:990px){
                                display:flex;
                                flex-direction: column;
                                align-items: center;
                            }
                        `}>
                            <div className={css`
                                        margin-bottom: 10px;
                                        font-size: 14px;
                                        font-weight: 600;
                                    `}>Carbon avoided</div>
                            <div className={css`
                                        font-size: 12px;
                                    `}>If use <span style={{ fontWeight: 600 }}>100%</span> green energy</div>
                            <div className={css`
                                        font-size: 12px;
                                    `}>Avoided <span style={{ fontWeight: 600 }}>{((saveCarbon?.carbon_kg || 0) / 1000).toFixed(2)}</span> tons of CO2 per year</div>
                            <div className={css`
                                        font-size: 12px;
                                    `}>which would taken <span style={{ fontWeight: 600 }}>{saveCarbon?.carbon_tree.toFixed(1)}</span> trees one year to absorb.</div>
                            {!consentId && <RobotBtn
                                robotId={1}
                                style={{
                                    width: 200,
                                    fontWeight: 400,
                                    padding: '5px',
                                    marginTop: 10,
                                    fontSize: 14
                                }} onClick={() => {
                                    setShowAccessSmtSection(true)
                                }}>Accurate calculation</RobotBtn>}
                        </div>
                    </Card>
                </MyCol>
            </Row>
            <GetUsageModal
                show={showAccessSmtSection}
                esiid={`${esiid}`}
                handleClose={() => {
                    setShowAccessSmtSection(false)
                }}
                handleSetConsentId={(id: string) => {
                    setConsentId(id)
                    setCookie('consent_id', id, { path: '/', maxAge: 3600 })
                }}
                handleFetchActualUsage={() => getMonthlyUsage()}
                defaultView={'smt'}
                zipcode={`${zipcode}`} />
        </>
    )
}

const PennyBar = ({ usage, premiseDetail, totalUsage }: { usage: any, premiseDetail: PremiseInfo, totalUsage: number }) => {
    const months = [t('Jan', { ns: 'common' }), t('Feb', { ns: 'common' }), t('Mar', { ns: 'common' }), t('Apr', { ns: 'common' }), t('May', { ns: 'common' }), t('Jun'),
    t('Jul', { ns: 'common' }), t('Aug', { ns: 'common' }), t('Sep', { ns: 'common' }), t('Oct', { ns: 'common' }), t('Nov', { ns: 'common' }), t('Dec', { ns: 'common' })];

    let chartData = [];
    for (let i in usage) {
        chartData.push({ name: months[parseInt(i)], value: usage[i] })
    }

    return (
        <Row>
            <MyCol md={12} lg={3} >
                <Card color='#ebfdff'>
                    <div className='d-flex flex-column justify-content-around' style={{ height: '100%' }}>

                        <div className='d-flex align-items-center'>
                            <img src="/images/icon_user.png" alt="" width={42} height={42} />
                            <div className='d-flex flex-column flex-grow-1 ms-4' >
                                <div style={{ fontSize: 14 }}>Annual Usage</div>
                                <div style={{
                                    fontSize: 20,
                                    fontWeight: 600, color: '#00454f'
                                }}>{(totalUsage).toLocaleString()}kwh</div>
                            </div>
                        </div>
                        <div className='mt-3 d-flex align-items-center'>
                            <img src="/images/icon_calendar.png" alt="" width={42} height={42} />
                            <div className='d-flex flex-column flex-grow-1 ms-4' >
                                <div style={{ fontSize: 14 }}>Average Monthly Usage</div>
                                <div style={{
                                    fontSize: 20,
                                    fontWeight: 600, color: '#00bdd6'
                                }}>{(totalUsage / 12).toLocaleString()}kwh</div>
                            </div>
                        </div>
                    </div>
                </Card>
            </MyCol>
            <MyCol md={12} lg={3}>
                <AnalysisResultsCard premiseDetail={premiseDetail} idx={0} />
            </MyCol>
            <MyCol md={12} lg={6}>
                <Card style={{ paddingBottom: 10 }} color='#ebfdff'>
                    <div style={{
                        fontSize: 12,
                        marginBottom: 5
                    }}>Rate is calculated by estimated usage by zip code and your SMT data. Adjust with your own estimates.</div>
                    <div id="usage-chart">
                        <ResponsiveContainer width={'99%'} height={130}>
                            <BarChart
                                data={chartData}
                                margin={{
                                    top: 12, right: 24, left: -12, bottom: 0,
                                }}
                            >
                                <XAxis dataKey="name" tick={{ fontSize: 11 }} stroke="#999" interval={1} />
                                <YAxis tick={{ fontSize: 11, fill: '#999' }} stroke="#fff" />
                                <CartesianGrid vertical={false} strokeDasharray="2" />
                                <Tooltip
                                    itemStyle={{
                                        fontSize: 13,
                                        fontWeight: 'bold',
                                        color: '#fff'
                                    }}
                                    labelStyle={{
                                        fontSize: 11,
                                        fontWeight: 'normal',
                                        color: '#fff'
                                    }}
                                    contentStyle={{
                                        backgroundColor: 'rgba(0, 0, 0, .6)',
                                        border: 0,
                                        borderRadius: 4
                                    }}
                                    cursor={{
                                        fill: `#00c1d930`
                                    }}
                                    content={({ active, payload, label }) => {
                                        if (active && label && payload) {
                                            return (
                                                <div className="chart-tooltip">
                                                    <div className="date">{label}</div>
                                                    <div className="value">{payload[0].value + 'kwh'}</div>
                                                </div>
                                            );
                                        }
                                        return null;
                                    }}
                                />
                                <Bar dataKey="value"
                                    isAnimationActive={true}
                                    radius={10}
                                    maxBarSize={10}
                                >
                                    {chartData.map((entry, idx) => (
                                        <Cell key={idx} fill="#00c1d9" />
                                    ))}
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </Card>
            </MyCol>
        </Row>
    )
}

const TempoBar = ({ usage, premiseDetail, totalUsage, setDayUsePresent }: { usage: any, premiseDetail: PremiseInfo, totalUsage: number, setDayUsePresent?: Function }) => {
    const months = [t('Jan', { ns: 'common' }), t('Feb', { ns: 'common' }), t('Mar', { ns: 'common' }), t('Apr', { ns: 'common' }), t('May', { ns: 'common' }), t('Jun'),
    t('Jul', { ns: 'common' }), t('Aug', { ns: 'common' }), t('Sep', { ns: 'common' }), t('Oct', { ns: 'common' }), t('Nov', { ns: 'common' }), t('Dec', { ns: 'common' })];

    let chartData = [];
    for (let i in usage) {
        chartData.push({ name: months[parseInt(i)], value: usage[i] })
    }

    const [consentId, setConsentId] = useState()
    const [cookies, setCookie, removeCookie] = useCookies(['consent_id'])

    const [nightUsed, setNightUsed] = useState(30)

    useEffect(() => {
        if (cookies && cookies.consent_id) {
            setConsentId(cookies.consent_id)
        }
    }, []);

    useUpdateEffect(() => {
        let timeout: NodeJS.Timeout | null = null;

        const debounce = (fn: any, time: number) => {
            clearTimeout(timeout as NodeJS.Timeout);
            timeout = setTimeout(() => {
                fn();
            }, time);
        };

        debounce(() => {
            setDayUsePresent && setDayUsePresent((100 - (nightUsed === 100 ? 99 : nightUsed)) / 100)
        }, 1000);

        return () => {
            clearTimeout(timeout as NodeJS.Timeout);
        };
    }, [nightUsed]);

    return (
        <Row>
            <MyCol md={12} lg={3} >
                <Card color='#fef6f1'>
                    <div className='d-flex flex-column justify-content-around' style={{ height: '100%' }}>

                        <div className='d-flex align-items-center'>
                            <img src="/images/icon_user_orange.png" alt="" width={42} height={42} />
                            <div className='d-flex flex-column flex-grow-1 ms-4' >
                                <div style={{ fontSize: 14 }}>Annual Usage</div>
                                <div style={{
                                    fontSize: 20,
                                    fontWeight: 600, color: '#602d08'
                                }}>{(totalUsage).toLocaleString()}kwh</div>
                            </div>
                        </div>
                        <div className='mt-3 d-flex align-items-center'>
                            <img src="/images/icon_calendar_orange.png" alt="" width={42} height={42} />
                            <div className='d-flex flex-column flex-grow-1 ms-4' >
                                <div style={{ fontSize: 14 }}>Average Monthly Usage</div>
                                <div style={{
                                    fontSize: 20,
                                    fontWeight: 600, color: '#ed7d2d'
                                }}>{(totalUsage / 12).toLocaleString()}kwh</div>
                            </div>
                        </div>
                    </div>
                </Card>
            </MyCol>
            <MyCol md={12} lg={3}>
                <AnalysisResultsCard premiseDetail={premiseDetail} idx={2} />
            </MyCol>
            <MyCol md={12} lg={6}>
                <Card style={{ paddingBottom: 10 }} color='#fdf6f1'>
                    <div className='d-flex'>
                        <div style={{ flex: 1 }}>
                            <div style={{ display: 'flex', justifyContent: 'center', gap: 15, marginBottom: 5 }}>
                                <span className={css`
                            font-size:12px;
                            &:before {
                            content: "";
                            display: inline-block;
                            width: 8px;
                            height: 8px;
                            background-color: #df7e2f;
                            border-radius: 50%;
                            margin-right: 2px;
                            vertical-align: middle;
                          }
                        `}> Daytime Used</span>
                                <span className={css`
                            font-size:12px;
                            &:before {
                            content: "";
                            display: inline-block;
                            width: 8px;
                            height: 8px;
                            background-color: #dfe1e6;
                            border-radius: 50%;
                            margin-right: 2px;
                            vertical-align: middle;
                          }
                        `}> Nighttime Used</span>
                            </div>
                            <div id="usage-chart">
                                <ResponsiveContainer width={'99%'} height={130}>
                                    <BarChart
                                        data={chartData}
                                        margin={{
                                            top: 12, right: 24, left: -12, bottom: 0,
                                        }}
                                    >
                                        <XAxis dataKey="name" tick={{ fontSize: 11 }} stroke="#999" interval={1} />
                                        <YAxis tick={{ fontSize: 11, fill: '#999' }} stroke="#fff" />
                                        <CartesianGrid vertical={false} strokeDasharray="2" />
                                        <Tooltip
                                            itemStyle={{
                                                fontSize: 13,
                                                fontWeight: 'bold',
                                                color: '#fff'
                                            }}
                                            labelStyle={{
                                                fontSize: 11,
                                                fontWeight: 'normal',
                                                color: '#fff'
                                            }}
                                            contentStyle={{
                                                backgroundColor: 'rgba(0, 0, 0, .6)',
                                                border: 0,
                                                borderRadius: 4
                                            }}
                                            cursor={{
                                                fill: '#df7e2f30'
                                            }}
                                            content={({ active, payload, label }) => {
                                                if (active && label && payload) {
                                                    return (
                                                        <div className="chart-tooltip">
                                                            <div className="date">{label}</div>
                                                            <div className="value">{payload[0].value + 'kwh'}</div>
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            }}
                                        />
                                        <Bar dataKey="value"
                                            isAnimationActive={true}
                                            radius={10}
                                            maxBarSize={10}
                                            shape={BarWithTopBorder(100 - nightUsed)}
                                        >
                                            {chartData.map((entry, idx) => (
                                                <Cell key={idx} fill="#D1D9D7" />
                                            ))}
                                        </Bar>
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        {!consentId && <div className='mt-4 mb-3'>
                            <ThemeProvider theme={outerTheme}>
                                <Slider
                                    aria-label="Temperature"
                                    valueLabelDisplay="auto"
                                    orientation="vertical"
                                    defaultValue={30}
                                    max={99}
                                    onChange={(val: any) => {
                                        setNightUsed(val.target.value)
                                    }}
                                    className={css`
                                        .MuiSlider-rail{
                                            opacity: 1 !important;
                                            color: #ed7d2d;
                                        }
                                        .MuiSlider-track {
                                            color: #dee1e6;
                                        }
                                    `}
                                />
                            </ThemeProvider>
                        </div>}
                    </div>
                </Card>
            </MyCol>
        </Row >
    )
}

const GetUsageModal: React.FC<GetUsageModalProps> = ({
    show,
    handleClose,
    handleSetConsentId,
    handleFetchActualUsage,
    defaultView,
    zipcode,
    esiid,
}) => {
    if (!show) return null
    return (
        <div className={`${show ? 'active' : ''}`}>
            <div className="side-modal-body">
                <div className="position-absolute" style={{ top: 20, right: 20 }}>
                    <BootstrapButton
                        className="close-btn"
                        variant="link"
                        onClick={() => handleClose()}
                        style={{ fontSize: 32, color: '#000' }}>
                        <FontAwesomeIcon icon="times" />
                    </BootstrapButton>
                </div>
                <GetUsageComponent
                    fetchActualUsage={() => {
                        handleFetchActualUsage()
                        handleClose()
                    }}
                    handleSetConsentId={handleSetConsentId}
                    defaultView={defaultView}
                    esiid={esiid}
                    inModal={true}
                    zipcode={zipcode}
                    forcastUsage={() => {
                        handleClose()
                    }}
                />
            </div>
            <div className="mask" onClick={() => handleClose()} style={{ zIndex: 2 }} />
        </div>
    )
}

const BarWithTopBorder = (numerator: number) => {

    return (props: any) => {
        const { x, y, width, height } = props;

        return (
            <g>
                <rect x={x} y={y} fill={"#dfe1e6"} width={width} height={height} stroke="none" className="bar" />
                <rect x={x} y={y} fill={'#df7e2f'} width={width} height={height * numerator / 100} stroke="none" className="bar-top" />
            </g>
        )
    }
}

const AnalysisResultsCard = ({ premiseDetail, idx }: { premiseDetail: any, idx: number }) => {
    const location = useLocation()
    const { address, zipcode, city, state } = qs.parse(
        location.search,
        { ignoreQueryPrefix: true }
    )
    const btnColor = ["#00bdd6", "#17a948", "#ed7d2d"]

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return <>
        <Card border >
            <div className='d-flex flex-column justify-content-around gap-4' style={{ height: '100%', marginTop: -5 }}>
                <div className='d-flex gap-3 align-items-center justify-content-between position-relative'>
                    <div className='d-flex align-items-center gap-2'>
                        <img src={`/images/icon_house${idx}.png`} alt="" width={44} height={44} />
                        <div className={css`
                    font-size: 14px;
                    font-weight: 600;
                    white-space: nowrap;
                    `}
                        >Analysis Results</div>
                    </div>
                    <div style={{
                        position: 'absolute',
                        top: 8,
                        right: -10,
                        width: 40
                    }}>
                        <MyButton
                            color={btnColor[idx]}
                            bkcolor='#fff'
                            bdcolor={btnColor[idx]}
                            sm
                            onClick={() => {
                                handleShow()
                            }}
                            style={{ fontSize: 12 }}
                        >Edit</MyButton>
                    </div>
                </div>
                <div style={{ fontSize: 12 }}>
                    {address},{city},{state} {zipcode}
                </div>
                <div className='d-flex gap-3'>
                    <div style={{ fontSize: 14, fontWeight: 500 }}>{premiseDetail?.detail?.lot_size || 0} sqft</div>
                    <div className='d-flex gap-1'>
                        <img src="/images/icon_bed.png" alt="" width={22} height={22} />
                        <p className='mb-0' style={{ fontSize: 14 }}>{premiseDetail?.detail?.bedrooms || 0}</p>
                    </div>
                    <div className='d-flex gap-1'>
                        <img src="/images/icon_bath.png" alt="" width={22} height={22} />
                        <p className='mb-0' style={{ fontSize: 14 }}>{premiseDetail?.detail?.bathrooms || 0}</p>
                    </div>
                </div>
            </div>
        </Card>

        <Modal show={show} onHide={handleClose} size='lg' id='font-family-fix'>
            <Modal.Header closeButton style={{ borderBottom: 'none', padding: '24px 36px 0' }}>
            </Modal.Header>

            <Modal.Body>
                <SearchAddressInner>
                    <div className='home-modal-title'>We are almost there!</div>
                    <div className='home-modal-text'>Estimate Usage with your house size, local weather and neighborhood historical data.</div>
                    <ValidateAddress />
                </SearchAddressInner>
            </Modal.Body>
        </Modal>
    </>
}

export { PennyBar, TempoBar, AuroraBar, MyCol }

