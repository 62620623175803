import styled from 'styled-components';

export const SearchAddressInner = styled.div`
    padding:10px 70px 70px;

    .home-modal-title {
        font-size: 32px;
        font-weight: 600;
        line-height: 1.1;
        margin-bottom: 20px;
    }

    .home-modal-text {
        font-size: 16px;
        color: #171a1f;
    }

    @media (max-width:768px){
        padding:0 10px 20px;
    }
`

export const HomeAsideBox = styled.div`
    display:flex;
    gap:20px;
    align-items:center;

    .img-aside{
        width:50%;
    }

    @media (max-width:990px){
    flex-direction:column;
        .img-aside{
            width:100%;
        }
    }
`