import { css } from "@emotion/css"
import qs from "qs"
import { useLocation, useNavigate } from "react-router"
import { PlanProps } from "../../types"
import { Card, RobotBtn } from "../styles/MyComponents.styled"

const btnBk = ['#00bdd6', '#17a948', '#ed7d2d']
const planName = ['Plan A', 'Plan B', 'Plan C']
const titleColor = ['#00454f', '#0a4d20', '#602d08']

const PlanCard = (props: {
    robot: number,
    planColor: string,
    plan: PlanProps,
    index: number,
    avgPrice: string,
    ShowDetailPlan: Function
}) => {
    let { plan, index, avgPrice, ShowDetailPlan } = props
    const navigate = useNavigate()
    const location = useLocation()
    const idx = parseInt(location.pathname.split('/').pop() || '0');
    const { address, zipcode, city, state, esiid, utility_code } = qs.parse(
        location.search,
        { ignoreQueryPrefix: true }
    )

    const handlePlan = () => {
        if (idx !== 2) {
            localStorage.setItem('ts_plan', JSON.stringify(plan));
            navigate(`/enrollment?address=${address}&zipcode=${zipcode}&city=${city}&state=${state}&esiid=${esiid}&plan_id=${plan?.plan_id}&utility_code=${utility_code}&step=service_date`)
        } else {
            window.open(plan.enroll_url, '_blank')
        }
    }

    let saving = 0;
    if (avgPrice !== '0') {
        saving = (parseFloat(avgPrice) - plan.rate / 100) / parseFloat(avgPrice);
    }

    return (
        <>
            <Card border className={css`
                &:hover{
                    box-shadow: 0 0 10px rgba(0,0,0,0.1);
                }
            `}>
                {idx === 0 && (saving > 0 ? <div className={css`
                    font-size:14px;
                    font-weight:500;
                    background:#ebfdff;
                    color:#00454f;
                    width:fit-content;
                    padding:3px 5px;
                    border-radius:2px;
                `}>Save {(saving * 100).toFixed(2)}%</div> : <div style={{ height: 27 }} />)}

                {idx === 1 && <div className={css`
                    font-size:14px;
                    font-weight:500;
                    background:#f0fdf3;
                    color:#0a4d20;
                    width:fit-content;
                    padding:3px 5px;
                    border-radius:2px;
                `}>Green 100%</div>}

                {idx === 2 && <div className={css`
                    font-size:14px;
                    font-weight:500;
                    background:#fdf6f1;
                    color:#602c08;
                    width:fit-content;
                    padding:3px 5px;
                    border-radius:2px;
                `}>{`Free from ${plan?.free_range_begin} to ${plan?.free_range_end}`}</div>}

                <div className="d-flex justify-content-between pt-3 pb-2 align-items-center">
                    <div className={css`
                        font-size: 18px; 
                        font-weight: 600;
                        color: ${titleColor[idx]};
                        text-align: center;
                    `}
                    >{planName[index]}</div>
                    <img src={`https://www.powerlego.com/ApiGateway/v2/logo/provider/${plan.provider_id}`} alt="" height={40} />
                </div>
                <div className="d-flex  gap-3 align-items-center">
                    <p className={css`
                            font-size: 48px;
                            font-weight: 600;
                        `}>
                        {plan.rate}¢
                    </p>
                    <p className={css`
                            color:#565E6C;
                        `}>/ kWh</p>
                </div>
                {idx === 0 && <p className={css`
                    color: #666;
                    margin: 0;
                    font-size: 13px;
                    font-weight: 600;
                `}>${(plan?.total / parseInt(plan?.contract_term)).toFixed(1)} Est. Avg. Monthly Cost</p >
                }

                <p className={css`
                        font-size: 16px;
                        font-weight: 600;
                        display: flex;
                        align-items: flex-end;
                        color:${btnBk[idx]};
                    `}
                >Month {plan?.contract_term}</p>
                <p className={css`
                        font-size: 14px; 
                        line-height: 26px; 
                        color: #171A1F;
                        font-style: italic;

                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        height: 3rem;
                        line-height: 1.5rem;
                    `}>
                    {plan?.plan_desc}
                </p>
                <div style={{
                    margin: '8px 0',
                    background: `${btnBk[idx]}24`,
                    cursor: 'pointer',
                    width: 'fit-content',
                    padding: '5px 8px',
                    borderRadius: 8,
                    fontSize: 12
                }} onClick={() => {
                    ShowDetailPlan(index)
                }}>Detail</div>
                <RobotBtn
                    robotId={idx}
                    onClick={handlePlan}
                > {idx === 2 ? 'Check Availability' : 'Enroll'}</RobotBtn>
            </Card >
        </>
    )
}

export default PlanCard
