import { Row, Container } from "react-bootstrap";
import { Link } from 'react-router-dom'
import Header from '../components/header'
import { ContentPage } from '../components/styles/Page.styled'

const PrivacyPolicy = () => (
  <>
    <Header />
    <ContentPage>
      <Container>
        <Row className="py-5">
          <h2 className="fw-bold mb-4">
            Privacy Policy
          </h2>

          <p className="mb-5">
            The Energyrus website (“the Service”) is owned and
            operated by eIQhome, LLC (“we” or “us”). This Privacy Policy
            describes our practices concerning the information we collect from
            you when you visit or use the Service. By visiting or using the
            Service, you consent to our collection and use of your information
            as described in this Privacy Policy.
          </p>

          <h5 className="my-5">
            1. Information We Collect
          </h5>
          <p className="mb-5">
            The information that we collect from you depends upon what sections
            of the Service you visit and what actions you take during your
            visit. In general, we collect the following kinds of information:
          </p>
          <p className="mb-5">
            (a) Personally identifiable information (PII): PII is information
            that personally identifies one individual from another. You
            voluntarily provide us with this information when you register on or
            use the Service. We may also collect PII about you from
            third-parties that provide us with such information at your request.
            We may collect information about you, from you or from
            third-parties, when third-parties, such as the Competitive Energy
            Providers (“CEPs”), contact us to request or verify information
            about you. The types of PII we may collect include:
          </p>

          <p className="mb-5">
            • Your name, Social Security number, email address, telephone number
            and/or IP address;
          </p>

          <p className="mb-5">
            • Your financial institution payment information, credit history and
            score, and employment status;
          </p>

          <p className="mb-5">
            • Your account ID, energy usage history, and meter number;
          </p>

          <p className="mb-5">
            • Account and credit details from the CEP you select;
          </p>

          <p className="mb-5">
            • Information about your home or business (e.g. area, heating system
            type);
          </p>

          <p className="mb-5">
            • Your energy service plan preferences; and
          </p>

          <p className="mb-5">
            • Other PII that you submit via the Service or which third-parties
            provide us with your consent.
          </p>

          <p className="mb-5">
            (b) General Information: General Information is information that is
            anonymous in nature and does not identify you as an individual. We
            collect General Information by using “cookies”, “web beacons” and
            similar tools, and it may include your computer IP address, unique
            mobile device identifier, browser type, ISP or carrier name, the URL
            of the last webpage you visited before visiting the Service, and
            actions you undertake on the Service when you visit. This
            information gives us insights on how people use the Service and may
            result in us tailoring advertisements on the Service to you based on
            your online activities or preferences.
          </p>

          <p className="mb-5">
            (c) Social Media-Related Content The Service may include features
            that let you share information from it to social media services.
            Your use of these sharing features may result in the collection or
            sharing of information about you by these sites and other
            third-parties according to the social media services’ own privacy
            policies. If you log in to your Energyrus account through
            social media platforms, we may access and store information through
            interfaces provided by the platforms in accordance with the
            platforms’ policies.
          </p>

          <h5 className="my-5">
            2. How We Use PII
          </h5>
          <p className="mb-5">
            We use your PII to provide you with the Service and to provide you
            with offers from third-parties such as CEPs. We may also use your
            PII to:
          </p>

          <p className="mb-5">
            • facilitate your application for energy services from various CEPs;
          </p>

          <p className="mb-5">
            • obtain your credit history and score;
          </p>

          <p className="mb-5">
            • verify documents;
          </p>

          <p className="mb-5">
            • create and manage your online accounts and profile;
          </p>

          <p className="mb-5">
            • contact you (by telephone, text, email or U.S. postal mail) to
            discuss your account and/or other matters that we believe may be of
            interest to you, including offers for third-party products and
            services;
          </p>

          <p className="mb-5">
            • conduct market research, website analysis, and other internal
            business purposes;
          </p>

          <p className="mb-5">
            • comply with the law, protect our legal rights, and enforce our{" "}
            <Link to="/terms_of_service">Terms of Service</Link>.
          </p>

          <p className="mb-5">
            We use the information we collect through cookies and other tools to
            improve the Service, customize your visits, determine which of our
            website’s pages you access, and customize the advertising materials
            the Service shows you.
          </p>

          <p className="mb-5">
            We may anonymize certain elements of your PII so that it can no
            longer be attributed to you. We will use this anonymized PII in the
            same manner as we use General Information.
          </p>

          <h5 className="my-5">
            3. How We Share Information
          </h5>
          <p className="mb-5">
            We may share the information that we collect from and about you with
            CEPs, with our affiliates, with third-party service providers, and
            with other third-parties with whom we partner (including cloud
            storage and web hosting providers, marketers and software
            maintenance service providers). We will not share any of your
            financial institution payment information with any third-party.
          </p>

          <p className="mb-5">
            CEPs handle their own customer service and billing and may use your
            information in accordance with their own privacy policies, which we
            encourage you to review before using our Service. Some of the
            third-parties described above, including Google, may use location
            information and other personal information to serve advertisements
            to you on other websites.
          </p>

          <p className="mb-5">
            Should you decide to customize your results or account using your
            actual usage data we may access your usage data, meter number, and
            premise identification number through an automated interface
            maintained by your utility or other third-party and we may share
            this information with CEPs.
          </p>

          <p className="mb-5">
            We may also disclose PII or other information about you (a) to law
            enforcement authorities, courts, or regulators; (b) to exercise or
            protect our legal rights; or (c) in the course of a business
            transaction in which we buy or sell assets. If we enter into an
            agreement to sell substantially all of our business assets, or if we
            become insolvent or enter into bankruptcy, your PII may be
            transferred to a third-party. In any of these cases, your PII could
            continue to be used by a successor or purchaser entity in the ways
            set forth in this policy.
          </p>

          <h5 className="my-5">
            4. How We Protect Information
          </h5>
          <p className="mb-5">
            We take the security of your information seriously and use
            reasonable technical and organizational measures to protect PII
            against unauthorized or unlawful processing and against accidental
            loss, destruction or damage. Our Service and the server(s) upon
            which it is hosted use Secure Socket Layering (SSL) to encrypt
            information including user login and password, user account content,
            credit card numbers, and other information.
          </p>

          <p className="mb-5">
            However, because no security system can be 100% effective, we do not
            guarantee the security of any information we may have collected from
            or about you. In addition, we have no control over the security of
            other web sites on the Internet that you might visit even when a
            link may appear to those websites. If there is a breach of security,
            then we may either post a notice through the service or attempt to
            notify you via email so that you can take reasonable protective
            steps.
          </p>

          <h5 className="my-5">
            5. How Long We Keep Information
          </h5>
          <p className="mb-5">
            We may retain information you provide to us for as long as we deem
            necessary to fulfill a business purpose or comply with a legal
            request. If you would like to object to our keeping your
            information, please follow the instructions in Section 6.
          </p>

          <h5 className="my-5">
            6. Your Rights and Control
          </h5>
          <p className="mb-5">
            You may at any time (i) configure your browser to opt out of the
            Service’s placement of cookies on your computer; (ii) withdraw any
            consent you previously gave us, or object to the processing of your
            PII by following the contact instructions in Section 12 (this may
            prevent you from using certain elements of the Service); (iii) stop
            receiving commercial emails from us by clicking the “unsubscribe”
            link at the bottom of the email (this will not prevent or interfere
            with us sending you non-marketing transactional communications);
            (iv) edit or delete some or all of your PII (apart from your name
            and contact information). Notwithstanding these options, we may keep
            some of the information you give us in an anonymized form that dos
            not reveal identifying information about you. You may exercise these
            rights by contacting us as provided in Section 12 or in other
            written communications we may send you.
          </p>

          <h5 className="my-5">
            7. Third-Party Links
          </h5>
          <p className="mb-5">
            The Service may include links to our business partners’ websites or
            other sites. If you choose to provide any information to any of
            these entities, they will collect and use your information pursuant
            to their own privacy policies, over which we have no control.
          </p>

          <h5 className="my-5">
            8. Tracking Technology and Do-Not-Track Requests
          </h5>
          <p className="mb-5">
            We and some service providers we work with may employ technology
            that tracks your online activities on the Service and on third-party
            websites. We currently offer no response to Do-Not-Track requests
            transmitted by web browsers. In addition, we have no control over
            the activities of third-party, advertiser, or service provider
            practices and they may employ technology that tracks your activity
            on the Service.
          </p>

          <h5 className="my-5">
            9. Children under 13
          </h5>
          <p className="mb-5">
            Our Service is not for users under the age of 13, and we do not
            knowingly collect any information from children under 13, except any
            third-party information you provide to us.
          </p>

          <h5 className="my-5">
            10. For California Residents
          </h5>
          <p className="mb-5">
            In addition to the rights set forth in this Privacy Policy,
            Residents of California (“California Residents”) may request
            information about the types of PII we share with third-parties for
            direct marketing purposes, and the identities of the third-parties
            with whom we have shared such information during the immediately
            preceding calendar year. California Residents may request further
            information about our compliance with this law by sending us a
            request to the email address provided in Section 12. We will respond
            to one such request per customer each year, and we cannot and will
            not reply to requests made by means other than those described in
            Section 12.
          </p>

          <p className="mb-5">
            There is no identity verification requirement to access the Service,
            and all users can visit it anonymously, but you must provide us with
            PII in order to effectively access and use it.
          </p>

          <h5 className="my-5">
            11. Changes to this Privacy Policy
          </h5>
          <p className="mb-5">
            We may change this Privacy Policy in the future. If we make any
            changes regarding the disclosure of Personal Information to
            third-parties, we will inform you via email or by posting a notice
            on our website. By continuing to use the Service following any such
            change, you agree to the amended Privacy Policy.
          </p>

          <h5 className="my-5">
            12. How to Contact Us
          </h5>
          <p className="mb-5">
            With any questions about this policy, please{" "}Contact Us
          </p>
        </Row>
      </Container>
    </ContentPage>
  </>
);

export default PrivacyPolicy;
